.label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  color: var(--design-bim-secondary-black);
  font-weight: 400;
  font-family: 'ALS Gorizont', sans-serif;
  white-space: nowrap;
  padding-top: 2px;
}

.label-text {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.label svg {
  flex-shrink: 0;
}

.label.uppercase {
  text-transform: uppercase;
}

.label svg circle {
  fill: var(--main-color);
}

.labelBorder {
  padding: 6px 10px 6px 6px;
  border-radius: 40px;
  background: var(--design-bim-true-white, #FFF);
  font-size: 12px;
  font-weight: 550;
  line-height: 120%;
  letter-spacing: 0.48px;
  border: 1px solid var(--offline-color);
  gap: 2px;
}

@media (max-width: 576px) {
  .label {
    font-size: 14px;
    letter-spacing: 0.56px;
    font-weight: 550;
  }

  .labelBorder {
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.48px;
  }
}
