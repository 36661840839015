.documentBlock {
  height: 100%;
  display: flex;
  align-items: center;
}

.documentBlock img {
  width: 100%;
  border-radius: 40px;
  object-fit: cover;
  height: 100%;
}

.documentBlock-imgBlock {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 40px;
  overflow: hidden;
}

.document__Img {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .documentBlock img {
    height: auto;
  }
}

@media (max-width: 576px) {
  .documentBlock img {
    border-radius: 24px;
  }
}
