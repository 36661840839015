.firstScreen {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 80px 118px;
  border-radius: 80px 80px 0px 0px;
  border: 1px solid black;
  border-bottom: none;
}

.firstScreen.online {
  background: var(--main-color);
  box-shadow: 0px 1px 0px 0px var(--main-color);
}

.firstScreen.offline {
  background: var(--main-offline-color);
  box-shadow: 0px 1px 0px 0px var(--main-offline-color);
}

.firstScreen.refresher.offline,
.firstScreen.refresher.online {
  border: none;
}

.secondScreen {
  padding: 80px 118px;
  border: 1px solid var(--design-bim-primary-black);
  background: var(--design-bim-true-white);
  border-radius: 0px 0px 80px 80px;
}

.firstScreen-info {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 850px;
}

.firstScreen-info-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.firstScreen-info__title {
  margin-top: 16px;
  font-family: "ALS Gorizont", sans-serif;
  font-size: 52px;
  line-height: 115%;
  font-weight: 750;
  padding-top: 2px;
}

.firstScreen.refresher.online .firstScreen-info__title,
.firstScreen.plain.online .firstScreen-info__title {
  color: var(--online-color-text);
}

.firstScreen.refresher.offline .firstScreen-info__title,
.firstScreen.plain.offline .firstScreen-info__title {
  color: var(--offline-color-text);
}

.firstScreen-list {
  margin-top: 32px;
  margin-bottom: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 16px;
}

.firstScreen-list-item {
  display: flex;
  gap: 6px;
}

.firstScreen-list-item-text {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 2px;
  line-height: 130%;
}

.firstScreen.refresher.online .firstScreen-list-item-text,
.firstScreen.plain.online .firstScreen-list-item-text {
  color: var(--online-color-text);
}

.firstScreen.refresher.offline .firstScreen-list-item-text,
.firstScreen.plain.offline .firstScreen-list-item-text {
  color: var(--offline-color-text);
}

.firstScreen-buttons {
  display: flex;
  gap: 16px;
}

.firstScreen-imgContainer {
  max-width: 760px;
  display: flex;
  align-items: flex-start;
}

.firstScreen-imgContainer img {
  width: 100%;
}

.firstScreen-list-item svg {
  flex-shrink: 0;
}

.secondScreen__title {
  margin-bottom: 40px;
}

.secondScreen-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.secondScreen-list-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.secondScreen-list-item .arrow path {
  fill: var(--main-color);
}

.refresher-secondScreen-content .secondScreen-list-item .arrow path {
  fill: var(--highlight-color);
}

.secondScreen-list-item__text {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: var(--design-bim-primary-black);
  padding-top: 2px;
  line-height: 130%;
}

.secondScreen-list-item__text a {
  color: var(--main-color);
}

.secondScreen-list-item__text span {
  font-weight: 600;
}

.firstScreen-info-row-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--offline-color-text);
  font-family: "ALS Gorizont", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-top: 2px;
  line-height: 130%;
}

.firstScreen.refresher.offline .firstScreen-info-row-item,
.firstScreen.refresher.online .firstScreen-info-row-item {
  color: var(--online-color-text);
}

.firstScreen-info-row-item__name {
  font-size: 14px;
  /* 19.6px */
  opacity: 0.6;
}

.firstScreen.online.refresher {
  background-color: transparent;
  border-radius: 0;
  padding-top: 130px;
  padding-bottom: 130px;
  box-shadow: none;
}

.refresher-firstScreen .secondScreen {
  background-color: transparent;
  border-radius: 0;
  padding-top: 140px;
  padding-bottom: 140px;
  border: none;
}

.firstScreen.online.refresher .firstScreen-info {
  width: 100%;
  max-width: unset;
}

.firstScreen.online.refresher .firstScreen-info__title {
  margin-top: 24px;
}

.firstScreen.online.refresher .firstScreen-info-row {
  gap: 48px;
  margin-top: 40px;
}

.firstScreen-info-fx {
  display: flex;
  align-items: flex-start;
}

.firstScreen-info-textBlock {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 48px;
  max-width: 810px;
}

.firstScreen-info__text {
  color: var(--offline-color-text);

  font-size: 20px;
  font-family: 'PT Golos', sans-serif;
  line-height: 140%;
}

.firstScreen.refresher.offline .firstScreen-info__text,
.firstScreen.refresher.online .firstScreen-info__text {
  color: var(--online-color-text);
}

.start-frame.refresher-firstScreen {
  padding-top: 78px;
  padding-bottom: 0;
}

.refresher-firstScreen .firstScreen-buttons {
  gap: 24px;
  margin-top: 48px;
}

.refresher-firstScreen .firstScreen-buttons {
  gap: 24px;
  margin-top: 48px;
}

.refresher-secondScreen-content-description {
  display: flex;
  align-items: center;
  gap: 24px;
}

.refresher-secondScreen-content-description__quote {
  flex-shrink: 0;
  display: none;
}

.refresher-secondScreen-content-description__quote * path {
  fill: var(--second-highlight-color);
}

.refresher-secondScreen-content-description-col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.refresher-secondScreen-content-description__text {
  color: var(--online-color-text);
  font-family: "ALS Gorizont", sans-serif;
  font-size: 24px;
  padding-top: 2px;
  line-height: 130%;
}

.info-favourite-course-area-wrapper .refresher-secondScreen-content-description__text {
  color: var(--offline-color-text);
}

.refresher-secondScreen-content-description__text span {
  color: var(--highlight-color);
}

.secondScreen-list-refresher {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 56px;
  row-gap: 40px;
}

.refresher-secondScreen-content {
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-width: 854px;
}

.refresher-secondScreen {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}

.refresher-secondScreen-comment-area {
  display: flex;
  flex-direction: column;
}

.refresher-secondScreen-comment {
  border-radius: 40px;
  border: 1px solid var(--design-bim-primary-black);
  background: var(--design-bim-true-white);
  padding: 56px 40px;
  max-width: 431px;
  position: relative;
}

.refresher-secondScreen-comment--quote {
  flex-shrink: 0;
  margin-bottom: -30px;
  margin-left: 30px;
  z-index: 1;
}

.refresher-secondScreen-comment--quote path {
  fill: var(--second-highlight-color);
}

.refresher-commentBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid #C9D4EB;
}

.refresher-commentBlock-author {
  display: flex;
  align-items: center;
  gap: 12px;
}

.refresher-commentBlock-author__img {
  border-radius: 80px;
  border: 1px solid var(--design-bim-tertiary-black, #656D7C);
  width: 32px;
  height: 32px;
}

.refresher-commentBlock-author-block {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--design-bim-primary-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  font-family: 'PT Golos', sans-serif;
}

.refresher-commentBlock__text {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--design-bim-secondary-black);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%; /* 25.2px */

  cursor: pointer;
}

.secondScreen-list-refresher .secondScreen-list-item__text {
  color: var(--online-color-text);
  font-family: "ALS Gorizont", sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding-top: 2px;
  line-height: 130%;
}

.secondScreen-list-refresher .secondScreen-list-item__text span {
  color: var(--highlight-color);
  font-weight: 400;
}

.refresher-commentInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.refresher-commentInfo-row {
  display: flex;
  align-items: center;
  gap: 24px;
}

.refresher-commentInfo-row-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.refresher-commentInfo-row-item .rating {
  font-size: 16px;
  color: var(--design-bim-primary-black);
}

.refresher-comment-Yandex {
  display: flex;
  align-items: center;
  gap: 2px;
}

.refresher-commentInfo__moreCom {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--main-color);
  /* 💙 Text-Golos/14-R */
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.14px;
  cursor: pointer;
}

.refresher-commentInfo__moreCom span {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: rgba(11, 104, 254, 0.40);
  -moz-text-decoration-color: rgba(11, 104, 254, 0.40);
}

@media (max-width: 1600px) {
  .firstScreen-imgContainer {
    max-width: 660px;
  }

  .firstScreen, .secondScreen {
    padding-left: 100px;
    padding-right: 100px;
  }

  .firstScreen-info__title {
    hyphens: auto;
  }
}

@media (max-width: 1400px) {
  .firstScreen-imgContainer {
    max-width: 500px;
  }

  .firstScreen, .secondScreen {
    padding: 75px 70px;
  }

  .firstScreen-list {
    margin-bottom: 50px;
  }

  .firstScreen.online.refresher {
    padding-top: 163px;
    padding-bottom: 135px;
  }

  .refresher-firstScreen .secondScreen {
    padding-top: 64px;
    padding-bottom: 85px;
  }

  .refresher-commentInfo-row {
    gap: 8px;
  }

  .refresher-commentInfo-row-item {
    gap: 8px;
  }
}

@media (max-width: 1200px) {
  .refresher-secondScreen-comment--quote {
    flex-shrink: 0;
    margin-bottom: -100px;
    margin-left: 40px;
    z-index: 1;
  }

  .refresher-commentInfo {
    flex-direction: row;
    justify-content: space-between;
  }

  .refresher-secondScreen-comment {
    margin-top: 70px;
    max-width: unset;
    width: 100%;
  }

  .refresher-secondScreen {
    flex-direction: column;
  }

  .refresher-secondScreen-content {
    display: flex;
    flex-direction: column;
    gap: 80px;
    max-width: unset;
  }

  .firstScreen-imgContainer {
    display: none;
  }

  .firstScreen-info {
    width: 100%;
    max-width: unset;
  }

  .firstScreen, .secondScreen {
    padding: 64px 40px;

  }

  .firstScreen {
    border-radius: 64px 64px 0px 0px;
  }

  .secondScreen {
    border-radius: 0px 0px 64px 64px;
  }

  .firstScreen-list {
    margin-bottom: 40px;
    grid-template-columns: max-content 1fr;
    row-gap: 20px;
  }

  .firstScreen-list-item {
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.18px;
  }

  .firstScreen-list-item svg {
    width: 24px;
    height: 24px;
  }

  .secondScreen-list {
    gap: 24px;
  }

  .firstScreen.online.refresher {
    padding-top: 80px;
    padding-bottom: 64px;
  }

  .refresher-firstScreen .secondScreen {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .refresher-secondScreen-comment {
    padding: 56px 40px;
  }

  .secondScreen-list-refresher {
    grid-template-columns: repeat(2, 1fr);
  }

  .firstScreen-info__title {
    hyphens: auto;
  }
}

@media (max-width: 768px) {
  .refresher-secondScreen-content-description__quote {
    display: none;
  }

  .firstScreen-info-row {
    flex-wrap: wrap;
  }

  .firstScreen-info__title {
    font-size: 44px;
    hyphens: auto;
  }

  .firstScreen.online.refresher {
    padding-top: 60px;
  }
}

@media (max-width: 650px) {
  .firstScreen-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .refresher-secondScreen-comment--quote {
    flex-shrink: 0;
    margin-bottom: -100px;
    margin-left: 20px;
    z-index: 1;
  }

  .refresher-secondScreen-content-description__quote {
    display: none;
  }

  .refresher-secondScreen-content-description {
    align-items: flex-start;
    gap: 0px;
  }

  .refresher-commentInfo {
    flex-direction: column;
  }

  .firstScreen, .secondScreen {
    padding: 48px 20px;
  }

  .firstScreen-list {
    grid-template-columns: 1fr;
    row-gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .firstScreen-info__title {
    font-size: 30px;
    line-height: 110%;
  }

  .firstScreen-list-item {
    font-size: 15px;
    line-height: 140%;
    letter-spacing: normal;
    gap: 8px;
  }

  .firstScreen-buttons .button {
    width: 100%;
  }

  .secondScreen-list {
    gap: 20px;
  }

  .firstScreen {
    border-radius: 40px 40px 0px 0px;
  }

  .secondScreen {
    border-radius: 0px 0px 40px 40px;
  }

  .secondScreen-list-item__text {
    font-size: 16px;
    letter-spacing: initial;
  }

  .secondScreen__title {
    margin-bottom: 32px;
  }

  .firstScreen-buttons {
    flex-direction: column;
  }

  .refresher-secondScreen-content {
    gap: 40px;
  }

  .secondScreen-list-refresher {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }

  .refresher-secondScreen-content-description svg {
    display: none;
  }

  .refresher-secondScreen-content-description__text {
    font-size: 18px;
  }

  .firstScreen.online.refresher {
    padding-top: 40px;
    padding-bottom: 28px;
  }

  .refresher-firstScreen .secondScreen {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .firstScreen.online.refresher .firstScreen-info-row {
    row-gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    column-gap: 24px;
    margin-top: 32px;
  }

  .firstScreen.online.refresher .firstScreen-info__title {
    font-size: 30px;
  }

  .refresher-secondScreen-comment {
    padding: 40px 20px;
    border-radius: 24px;
  }

  .refresher-commentBlock__text {
    font-size: 16px;
  }

  .refresher-commentBlock {
    padding-bottom: 24px;
  }

  .refresher-commentInfo-row .rating svg {
    width: 16px;
    height: 16px;
  }
}
