.header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 10px);
  height: 78px;
  z-index: 3;
  transform: translateY(-100px);
  transition: transform 0.3s ease-in-out;
}

.header.plain.online,
.header.plain.offline {
  background: var(--background-color);
  backdrop-filter: blur(5px);
}

.header.catalog,
.header.refresher.online {
  background: var(--main-color);
}
.header.refresher.offline {
  background: #000;
}

.menu-close-icon {
  cursor: pointer;
}

.menu-close-icon.catalog svg *,
.menu-close-icon.refresher.online svg * {
  stroke: var(--main-text-color);
}

.menu-close-icon.plain.online,
.menu-close-icon.plain.offline {
  stroke: var(--design-bim-primary-black);
}

.menu-close-icon.catalog:hover svg *,
.menu-close-icon.refresher.online:hover svg * {
  stroke: var(--design-bim-true-white);
  transition: stroke 0.3s ease-in-out;
}

.menu-close-icon.plain.online:hover svg *,
.menu-close-icon.plain.offline:hover svg * {
  stroke: var(--main-color);
  transition: stroke 0.3s ease-in-out;
}

.menu-icon {
  cursor: pointer;
}

.menu-icon:hover svg * {
  fill: var(--main-color);
  transition: stroke 0.3s ease-in-out;
}

@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .header {
    width: 100%;
  }
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 100%;
  gap: 32px;
}

body {
  background-color: var(--background-color);
  font-weight: 400;
}

.header-wrapper-leftSide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 80px;
  height: 100%;
}

.header-wrapper-rightSide {
  display: flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.header-button {
  border: none;
  outline: none;
  background: transparent;
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  line-height: 115%;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}

.header-button.plain.online,
.header-button.plain.offline {
  color: var(--main-color);
}

.header-button.catalog,
.header-button.refresher.online,
.header-button.refresher.offline {
  color: var(--main-text-color);
}

.header-button::before {
  content: '';
  opacity: 0;
}

.header-button:hover::before,
.header-button--active.header-button::before {
  content: '';
  width: 100%;
  height: 3px;
  top: 75px;
  position: absolute;
  left: 0;
  border-radius: 3px;
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.header-button.plain.online:hover::before,
.header-button--active.header-button.plain.online::before,
.header-button.plain.offline:hover::before,
.header-button--active.header-button.plain.offline::before {
  background: var(--main-color);
}

.header-button.catalog:hover::before,
.header-button--active.header-button.catalog::before,
.header-button.refresher.online:hover::before,
.header-button--active.header-button.refresher.online::before {
  background: var(--main-text-color);
}

.header-nav {
  margin-left: 80px;
}

.nav {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1540px;
}

.header-menu {
  display: none;
}

@media (max-width: 1600px) {
  .header-wrapper-leftSide {
    gap: 35px;
  }

  .header-nav {
    margin-left: 35px;
  }

  .header-wrapper {
    gap: 15px;
  }
}

@media (max-width: 1400px) {
  .nav, .header-wrapper-rightSide {
    display: none;
  }

  .header-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .header-wrapper {
    padding: 0 20px;
    height: 72px;
  }

  .header {
    height: 72px;
  }
}
