.other-courses-frame-cover {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

#other-courses-frame.frame {
  padding-top: 0;
}

.other-courses-frame-cover .title-area-text,
.other-courses-frame-cover .title-area-text,
.other-courses-frame-cover .title-area-text {
  color: var(--design-bim-primary-black);
}

.other-courses-frame-cover .title-area-text span,
.other-courses-frame-cover .title-area-text span {
  color: var(--main-color);
}

.other-courses-frame-cover .all-courses-button {
  color: var(--highlight-color);
}

.other-courses-frame-cover .all-courses-button.plain.offline,
.other-courses-frame-cover .all-courses-button.refresher.online {
  color: var(--main-color);
}

.oc-courses-area {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.oc-course {
  display: flex;
  flex-direction: column;

  border-radius: 32px;
  background: var(--main-color);
  padding: 80px 32px 40px 32px;
  gap: 24px;

  text-decoration: none;
  position: relative;
  transition: transform 0.2s ease-in;
  cursor: pointer;
  transform: scale(1);

  height: 100%;
  border: 1px solid black;;
}

.oc-course.plain.offline,
.oc-course.refresher.offline {
  background: var(--main-offline-color);
}

.oc-course.plain.online.catalog,
.oc-course.plain.offline.catalog,
.oc-course.refresher.online.catalog,
.oc-course.refresher.offline.catalog {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 40px 100px;
}

.oc-course.plain.online.catalog .oc-info,
.oc-course.plain.offline.catalog .oc-info,
.oc-course.refresher.online.catalog .oc-info,
.oc-course.refresher.offline.catalog .oc-info {
  max-width: 800px;
}

.oc-course.plain.online.catalog .oc-image,
.oc-course.plain.offline.catalog .oc-image,
.oc-course.refresher.online.catalog .oc-image,
.oc-course.refresher.offline.catalog .oc-image {
  max-height: 220px;
}

.oc-blockMain {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.oc-course:hover {
  transform: scale(1.04);
}

.oc-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.oc-title {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 25.3px */
  letter-spacing: -0.44px;
  padding-top: 2px;
}

.oc-course.refresher.online .oc-title,
.oc-course.plain.online .oc-title {
  color: var(--online-color-text);
}

.oc-course.refresher.offline .oc-title,
.oc-course.plain.offline .oc-title {
  color: var(--offline-color-text);
}

.oc-description {
  opacity: 0.6;
  font-family: "PT Golos", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.oc-course.refresher.online .oc-description,
.oc-course.plain.online .oc-description {
  color: var(--online-color-text);
}

.oc-course.refresher.offline .oc-description,
.oc-course.plain.offline .oc-description {
  color: var(--offline-color-text);
}

.oc-row {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.oc-infoBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.oc-label {
  padding: 4px 10px;
  border-radius: 40px;
  font-family: "PT Golos";
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.13px;
}

.oc-course.refresher.online .oc-label,
.oc-course.plain.online .oc-label {
  border: 1px solid var(--online-color-text);
  color: var(--online-color-text);
}

.oc-course.refresher.offline .oc-label,
.oc-course.plain.offline .oc-label {
  border: 1px solid var(--offline-color-text);
  color: var(--offline-color-text);
}

.oc-label--back {
  border-color: var(--design-bim-true-white);
  background: var(--design-bim-true-white);
  color: var(--design-bim-primary-black);
}

.oc-course.refresher.online .oc-label--back,
.oc-course.plain.online .oc-label--back {
  border-color: var(--online-color-text);
  background: var(--online-color-text);
  color: var(--main-color);
}

.oc-course.refresher.offline .oc-label--back,
.oc-course.plain.offline .oc-label--back {
  border-color: var(--offline-color-text);
  background: var(--offline-color-text);
  color: var(--offline-color);
}

.oc-dataStart {
  display: flex;
  align-items: center;
  font-family: "PT Golos";
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-top: auto;
  margin-bottom: 0;
  gap: 8px;
}

.oc-dataStart.plain.online,
.oc-dataStart.refresher.online {
  color: var(--highlight-color);
}

.oc-dataStart.plain.offline,
.oc-dataStart.refresher.offline {
  color: var(--highlight-color);
}

.oc-dataStart.plain.offline svg *,
.oc-dataStart.refresher.offline svg * {
  stroke: var(--highlight-color);
}

.oc-groupSetup {
  display: flex;
  align-items: center;
  font-family: "PT Golos", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-top: auto;
  margin-bottom: 0;
}

.oc-groupSetup svg * path {
  fill: var(--second-highlight-color);
}

.oc-groupSetup.plain.online,
.oc-groupSetup.refresher.online {
  color: var(--highlight-color);
}

.oc-groupSetup.plain.online svg *,
.oc-groupSetup.refresher.online svg * {
  /*stroke: var(--brand-itmo-green-lizard);*/
}

.oc-groupSetup.plain.offline svg *,
.oc-groupSetup.refresher.offline svg * {
  /*stroke: var(--second-highlight-color);*/
}

.oc-groupSetup.plain.offline,
.oc-groupSetup.refresher.offline {
  color: var(--second-highlight-color);
}

.oc-groupSetup .clock-icon {
  width: 40px;
  height: 40px;
}

.oc-groupSetup.introduction-frame {
  font-size: 18px;
}

.oc-formatCourse {
  color: #000;
  font-family: "PT Golos";
  font-size: 11px;
  font-weight: 600;
  line-height: 140%; /* 15.4px */
  letter-spacing: -0.11px;
  transform: rotate(-15deg);
  border: 2px solid #000;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background: var(--highlight-color);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -5px;
  top: -25px;
  text-transform: uppercase;
}

.oc-formatCourse.plain.offline,
.oc-formatCourse.refresher.offline {
  background: var(--second-highlight-color);
  color: #000;
}

.oc-formatCourse.offline::before,
.oc-formatCourse.online::before {
  display: block;
  content: '';
  width: 72px;
  height: 72px;
  position: absolute;
  border-radius: 50%;
  border: 3px solid var(--brand-itmo-white);
}

.oc-formatCourse.catalog {
  right: unset;
  left: -5px;
  top: -25px;
}

.oc-image-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.oc-image {
  display: flex;
  max-height: 150px;
  align-self: start;
}

@media (max-width: 1200px) {
  .oc-courses-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  .other-courses-frame-cover {
    gap: 40px;
  }

  .oc-course {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 60px 32px 40px 32px;
  }

  .other-courses-frame-bg {
    margin-top: 24px;
  }

  .oc-image {
    align-self: center;
  }

  .oc-course.plain.online.catalog .oc-image,
  .oc-course.plain.offline.catalog .oc-image,
  .oc-course.refresher.online.catalog .oc-image,
  .oc-course.refresher.offline.catalog .oc-image {
    max-width: 200px;
  }
}

@media (max-width: 912px) {
  .oc-image-area {
    flex-direction: row;
    justify-content: start;
  }

  .oc-course.plain.online.catalog,
  .oc-course.plain.offline.catalog,
  .oc-course.refresher.online.catalog,
  .oc-course.refresher.offline.catalog {
    flex-direction: column;
    padding: 56px 40px;
  }

  .oc-course.plain.online.catalog .oc-image,
  .oc-course.plain.offline.catalog .oc-image,
  .oc-course.refresher.online.catalog .oc-image,
  .oc-course.refresher.offline.catalog .oc-image {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .title-description-area-title-row {
    flex-direction: column;
    gap: 16px;
  }

  .title-description-area-title-row-button {
    flex-grow: initial;
    justify-content: flex-start;
  }

  .oc-course {
    flex-direction: column;
    padding: 40px 20px 20px 20px;
    gap: 16px;
  }

  .oc-image-area {
    flex-direction: row;
    justify-content: start;
  }

  .oc-course.plain.online.catalog,
  .oc-course.plain.offline.catalog,
  .oc-course.refresher.online.catalog,
  .oc-course.refresher.offline.catalog {
    flex-direction: column;
  }

  .oc-course.plain.online.catalog .oc-image,
  .oc-course.plain.offline.catalog .oc-image,
  .oc-course.refresher.online.catalog .oc-image,
  .oc-course.refresher.offline.catalog .oc-image {
    max-width: 200px;
  }
}

@media (max-width: 576px) {
  .oc-course.plain.online.catalog,
  .oc-course.plain.offline.catalog,
  .oc-course.refresher.online.catalog,
  .oc-course.refresher.offline.catalog {
    flex-direction: column;
  }

  .oc-course {
    flex-direction: column;
    padding: 40px 20px 20px 20px;
    gap: 16px;
  }

  .oc-image {
    max-width: 150px;
    align-self: start;
  }

  .oc-course.plain.online.catalog .oc-image,
  .oc-course.plain.offline.catalog .oc-image,
  .oc-course.refresher.online.catalog .oc-image,
  .oc-course.refresher.offline.catalog .oc-image {
    max-width: 150px;
  }

  .oc-course.plain.online.catalog .button,
  .oc-course.plain.offline.catalog .button,
  .oc-course.refresher.online.catalog .button,
  .oc-course.refresher.offline.catalog .button {
    font-size: 15px;
    padding: 10px 16px 10px 20px;
  }

  .oc-course.plain.online.catalog .arrow,
  .oc-course.plain.offline.catalog .arrow,
  .oc-course.refresher.online.catalog .arrow,
  .oc-course.refresher.offline.catalog .arrow {
    width: 20px;
    height: 20px;
  }

  .oc-course.plain.online.catalog,
  .oc-course.plain.offline.catalog,
  .oc-course.refresher.online.catalog,
  .oc-course.refresher.offline.catalog {
    flex-direction: column;
    padding: 40px 20px;
  }

  .oc-label {
    font-size: 11px;
  }

  .oc-description {
    font-size: 14px;
  }

  .oc-blockMain {
    gap: 48px;
  }

  .oc-title {
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.2px;
  }

  .oc-row {
    gap: 4px;
  }

  .other-courses-frame-cover {
    gap: 32px;
  }

  .other-courses-frame-bg {
    margin-top: 20px;
  }
}
