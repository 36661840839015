.info-favourite-course-area {
  width: calc(100vw - 10px);
  background: black;
  margin-left: calc(50% - 50vw);
}


@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .info-favourite-course-area {
    width: calc(100vw);
    background: black;
    margin-left: calc(50% - 50vw);
  }
}

@media (max-width: 360px) {
  .info-favourite-course-area {
    min-width: 360px;
    margin-left: -20px;
  }
}

.info-favourite-course-area-wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.info-favourite-course-area-wrapper--section-1 {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}

.info-favourite-course-area-wrapper--section-1--title {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 854px;
  gap: 72px;
}

.info-favourite-course-area-wrapper--section-1--title-main {
  color: var(--brand-itmo-white, #FFF);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 550;
  line-height: 120%;
  padding-top: 2px;
}

.info-favourite-course-area-wrapper--section-1--title-main span {
  color: var(--highlight-color);
}

.info-favourite-course-area-wrapper--section-1--comment {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  color: white;
}

@media (max-width: 1200px) {
  .info-favourite-course-area-wrapper--section-1--title-main {
    font-size: 35px;
  }

  .info-favourite-course-area-wrapper--section-1 {
    flex-direction: column;
    gap: 40px;
  }

  .info-favourite-course-area-wrapper {
    gap: 64px;
  }
}

@media (max-width: 576px) {
  .info-favourite-course-area-wrapper--section-1 {
    gap: 10px;
  }

  .info-favourite-course-area-wrapper--section-1--title {
    gap: 32px;
  }

  .info-favourite-course-area-wrapper--section-1--title-main {
    font-size: 22px;
  }

  .info-favourite-course-area-wrapper {
    gap: 48px;
  }
}
