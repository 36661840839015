* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.shedule_block {
    width: 100%;
    background-color: #000000;
    padding: 80px 118px;
}

.shedule_content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
    color: #fff;
    max-width: 1400px;
    margin: 0 auto;
}

.shedule_header {
    font-family: ALS Gorizont, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 750;
    letter-spacing: -.4px;
    line-height: 120%;
}

.shedule_header>span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    color: #FC74FD;
}

.shedule_dates {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    column-gap: 120px;
    max-width: 700px;
}

.shedule_date {
    font-family: ALS Gorizont;
    border-radius: 120px;
    background-color: #5B16A1FF;
    padding: 16px 32px;
    display: grid;
    gap: 16px;
}

.shedule_name {
    font-size: 24px;
    align-items: center;
    display: flex;
    gap: 12px;
}

.shedule_info {
    font-size: 16px;
    align-items: center;
    display: flex;
    gap: 12px;
}

@media (max-width: 1400px) {
    .shedule_block {
        padding: 75px 70px;
    }
}

@media (max-width: 1200px) {
    .shedule_header {
        font-size: 36px;
        letter-spacing: -.36px;
    }

    .shedule_block {
        padding: 64px 40px;
    }

}

@media (max-width: 768px) {
    .shedule_date {
        gap: 12px;
        border-radius: 80px;
    }

    .shedule_name {
        font-size: 22px;
    }
}


@media (max-width: 576px) {
    .shedule_block {
        padding: 48px 20px;
    }

    .shedule_header {
        font-size: 36px;
        letter-spacing: -.36px;
    }

    .shedule_date {
        gap: 8px;
    }

    .shedule_name {
        font-size: 20px;
    }

    .shedule_info {
        font-size: 14px;
    }
}