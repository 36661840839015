.faq-frame-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  gap: 20px;
}

#faq-frame.frame {
}

.faq-frame-wrapper {
  width: 100%;
  max-width: 1165px;
}

.faq-frame-wrapper a {
  color: var(--highlight-color);
}

@media (max-width: 1200px) {
  .faq-frame-row {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
    align-items: start;
  }
}

@media (max-width: 576px) {
  .faq-frame {
    padding-left: 20px;
    padding-right: 20px;
  }

  .faq-frame-row {
    margin-bottom: 24px;
  }
}
