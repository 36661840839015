.composed-program-frame {
}

.cpf-area {
  display: flex;
  gap: 64px;
}

.cpf-area-left {
  display: flex;
  flex-direction: column;
  gap: 120px;
  flex: 2;
}

.cpf-left-title-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cpf-left-title-area-text {
  color: var(--design-bim-true-white, #FFF);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 550;
  line-height: 130%;
  padding-top: 2px;
}

.cpf-left-title-area-text span {
  color: var(--highlight-color);
}

.cpf-left-title-area-tags-area {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.cpf-left-title-area-tag {
  display: flex;
  padding: 6px 16px;
  align-items: center;
  color: var(--design-bim-true-white, #FFF);
  background: var(--tag-color);
  border-radius: 40px;

  font-family: 'PT Golos', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.2px;
  white-space: nowrap;
}

.cpf-left-module {
  display: flex;
  flex-direction: column;
  gap: 48px;
  scroll-margin: 100px;
}

.cpf-left-modules-all-button {
  display: none;
}

.cpf-left-module-info {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cpf-left-module-info-title {
  color: var(--design-bim-true-white, #FFF);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 550;
  padding-top: 2px;
}

.cpf-left-module-info-title span {
  color: var(--highlight-color);
}

.cpf-left-module-info-link-area {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.cpf-left-module-info-link {
  display: flex;
  gap: 12px;
}

.cpf-left-module-info-link-text {
  color: var(--brand-itmo-white, #FFF);

  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.cpf-left-module-info-link-text a {
  color: var(--highlight-color);
  text-decoration: unset;
}

.cpf-area-right {
  min-width: 400px;
  flex: 1;
}

.cpf-left-modules {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.cpf-right-modules {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 250px;
  position: sticky;
  top: 120px;
  /* overflow-y: scroll; */
}

.cpf-right-modules::-webkit-scrollbar {
  display: none;
}

.cpf-right-modules-item {
  color: var(--brand-itmo-white);
  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.cpf-right-modules-item.current {
  color: var(--highlight-color);
}

.cpf-right-modules-item:hover {
  cursor: pointer;
}

.cpf-left-module-info-metadata {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.cpf-left-module-info-metadata-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cpf-left-module-info-metadata-content {
  display: flex;
  gap: 8px;
  color: #FFF;
  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.cpf-left-module-info-metadata-content.video,
.cpf-left-module-info-metadata-inner-content-value {
  color: var(--highlight-color);
  cursor: pointer;
}

.cpf-left-module-info-description {
  color: rgba(255, 255, 255, 0.80);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.cpf-left-module-info-description span {
  font-weight: 600;
}

.cpf-left-module-info-accordion,
.cpf-left-module-info-accordion-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cpf-left-module-info-accordion-item-zone {
  display: flex;
  flex-direction: column;
  scroll-margin: 100px;
}

.cpf-left-module-info-accordion-item {
  display: flex;
  padding: 36px 32px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: var(--tag-color);
  gap: 32px;
}

.cpf-left-module-info-accordion-item-icon {
  width: 24px;
  height: 24px;
}

.cpf-left-module-info-accordion-item:hover {
  background: var(--main-color);
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.cpf-left-module-info-accordion-item.active {
  background: var(--main-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cpf-left-module-info-accordion-item-text {
  color: var(--design-bim-true-white, #FFF);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.4px;
  padding-top: 2px;
}

.cpf-left-module-info-accordion-item-extra {
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid var(--main-color);
  padding: 16px 32px 48px 32px;
}

.cpf-left-module-info-accordion-item-extra-area {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cpf-left-module-info-accordion-item-extra-tasks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cpf-left-module-info-accordion-item-extra-area-title {
  color: rgba(255, 255, 255, 0.80);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
}

.cpf-left-module-info-accordion-item-extra-inner-content {
  display: flex;
  gap: 8px;
}

.cpf-left-module-info-accordion-item-extra-area-text {
  color: rgba(255, 255, 255, 0.80);
  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.cpf-left-module-info-accordion-item-extra-area-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cpf-left-module-button-area {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.cpf-left-module-button-area:hover {
  cursor: pointer;
}

.cpf-left-module-button-area:hover .cpf-left-module-button {
  color: var(--design-bim-primary-black);
  background: var(--highlight-color);
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.cpf-left-module-button-area:hover svg path {
  stroke: var(--design-bim-primary-black);
  transition: stroke 0.2s ease-in-out;
}

.cpf-left-module-button {
  display: flex;
  padding: 14px 14px 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--highlight-color);

  color: var(--highlight-color);
  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.16px;
}

@media (max-width: 1200px) {
  .cpf-area-right {
    display: none;
  }

  .cpf-left-module-button {
    width: 100%;
  }

  .cpf-area-left {
    gap: 80px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .cpf-left-title-area-text {
    font-size: 36px;
  }

  .cpf-left-title-area-tag {
    font-size: 20px;
  }

  .cpf-left-module-info-title {
    font-size: 28px;
  }

  .cpf-left-module-info-link-text {
    font-size: 14px;
  }

  .cpf-left-module-info-metadata-content {
    font-size: 14px;
  }

  .cpf-left-module-info-description {
    font-size: 18px;
  }

  .cpf-left-module-info-accordion-item-text {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .cpf-left-modules-all-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 14px 14px 14px 20px;
    border-radius: 12px;
    border: 1px solid #0D0D0D;
    background: var(--highlight-color);
    color: var(--design-bim-primary-black, #0D0D0D);
  }

  .cpf-left-modules-all-button:hover {
    background: var(--brand-itmo-white);
    transition: background 0.3s ease-in-out;
  }

  .cpf-left-modules-all-button--text {
    font-family: 'PT Golos', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.16px;
  }

  .cpf-left-module:nth-child(n + 3) {
    display: none;
  }

  .all-modules-visible.cpf-left-module:nth-child(n + 3) {
    display: flex;
  }

  .cpf-left-module-info-accordion-item {
    padding: 16px;
  }

  .cpf-left-title-area-text {
    font-size: 22px;
  }

  .cpf-left-title-area-tag {
    font-size: 14px;
  }

  .cpf-left-module-info-title {
    font-size: 20px;
  }

  .cpf-left-module-info-link-text {
    font-size: 13px;
  }

  .cpf-left-module-info-metadata-content {
    font-size: 14px;
  }

  .cpf-left-module-info-description {
    font-size: 16px;
  }

  .cpf-left-module-info-accordion-item-text {
    font-size: 15px;
  }

  .cpf-left-module-info-metadata {
    row-gap: 16px;
    column-gap: 12px;
  }

  .cpf-left-module-info-accordion-item-icon {
    width: 12px;
    height: 12px;
  }

  .cpf-left-module-info-accordion {
    flex-direction: column-reverse;
  }

  .cpf-left-modules {
    gap: 48px;
  }

  .cpf-left-module-button-area {
    margin-bottom: 28px;
    margin-top: 0;
  }

  .cpf-area-left {
    gap: 48px;
  }

  .cpf-left-module-info {
    gap: 20px;
  }
}
