.modal {
  position: fixed;
  top: 78px;
  right: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 78px);
  z-index: 2;
  overflow-y: auto;
  background: #fff;
  opacity: 0;
}

.modal-container {
  margin-top: auto;
  padding: 0 40px;
}

.modal-container-top {
  padding: 0 40px;
}

.burgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.burgerMenu-courseInfo {
  padding: 56px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.burgerMenu-courseInfo__title {
  font-family: 'ALS Gorizont', sans-serif;
  font-weight: 550;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.8px;
  color: var(--design-bim-primary-black);
  padding-top: 2px;
}

.burgerMenu-buttonContainer {
  padding-top: 56px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 30px;
}

.burgerMenu-buttonContainer .button {
  width: 50%;
}

@media (max-width: 576px) {
  .modal-container {
    padding: 0px 20px;
  }

  .modal-container-top {
    padding: 0px 20px;
  }


  .burgerMenu-courseInfo {
    gap: 12px;
    padding: 40px 0px 20px 0px;
  }

  .burgerMenu-courseInfo__title {
    font-size: 28px;
    letter-spacing: -0.56px;
  }

  .modal {
    height: calc(var(--vh, 1vh) * 100 - 72px);
    top: 72px;
  }

  .burgerMenu-buttonContainer {
    padding-top: 40px;
    gap: 8px;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .burgerMenu-buttonContainer .button {
    width: 100%;
  }
}
