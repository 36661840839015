#filter-modal-frame-id .modalMain-wrapper {
  padding: 0 20px;
  border-radius: 0;
  border: 0;
}

.filter-modal-area {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 100px;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
}

.filter-modal-area::-webkit-scrollbar {
  display: none;
}

.filter-modal-area-title {
  display: flex;
  justify-content: space-between;
}

.filter-modal-area-title--text {
  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 550;
  line-height: 110%; /* 33px */
  letter-spacing: -0.6px;
  padding-top: 2px;
}

.filter-modal-area-title--reset-button {
  display: flex;
  gap: 8px;
  align-items: center;

  color: var(--main-color);
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.14px;

  cursor: pointer;
}

.filter-modal-area-title--reset-button * path {
  stroke: var(--main-color);
}

.filter-modal-area-title--apply-button {
  display: flex;
  padding: 18px 24px 18px 28px;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  border-radius: 12px;
  background: var(--main-color);
  margin-top: auto;
  margin-bottom: 36px;
  border: 1px solid var(--offline-color);
}

.filter-modal-area-title--apply-button--text {
  color: var(--online-color-text);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.36px;
}

.filter-modal-area-title--apply-button--text span {
  color: var(--highlight-color);
}
