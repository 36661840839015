.price-frame-content {
  display: flex;
  justify-content: space-between;
  gap: 45px;
}

.pf-bg-svg {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

.pf-leftSide {
  display: flex;
  flex-grow: 1;
  max-width: 500px;
}

.pf-rightSide {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 48px;
  max-width: 750px;
}

.pf-prices {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.pf-point {
  color: var(--design-bim-true-white, #FFF);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}

.pf-prices .card-list-area {
  gap: 16px;
}

@media (max-width: 1200px) {
  .price-frame-content {
    flex-direction: column;
    gap: 64px;
  }

  .pf-leftSide {
    max-width: unset;
  }

  .pf-rightSide {
    max-width: unset;
  }

  .pf-bg-svg {
    display: none;
  }

  .pf-prices .card-list-area {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .pf-rightSide .button {
    width: 100%;
  }

  .price-frame-content {
    flex-direction: column;
    gap: 40px;
  }

  .pf-prices .card-list-area {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .pf-point {
    font-size: 16px;
  }

  .pf-rightSide {
    gap: 40px;
  }
}
