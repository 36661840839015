.offline-course {
  background-color: var(--background-color);
  /* user-select: none; */
  width: calc(100vw - 10px);
}

@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .offline-course {
    width: 100%;
  }
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .all-courses,
body.modal-open .modalMenu {
  width: calc(100% + 10px);
  margin-right: -10px;
}
