.bottom-info-zone {
  position: fixed;
  bottom: 0;
  height: 60px;
  background: var(--darken-main-color);
  color: white;
  z-index: 2;
  transform: translateY(100px);
  width: calc(100vw - 10px);
  transition: transform 0.3s ease-in-out;
  border-top: 1px solid black;
}

@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .bottom-info-zone {
    width: 100%;
  }
}

.bottom-info-zone-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  height: 100%;
  gap: 32px;
}

.bottom-info-zone-wrapper-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.bottom-info-zone-wrapper-info-title {
  font-family: "ALS Gorizont", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100vw - 650px);
  padding-top: 5px;
  color: var(--online-color-text);
}

.bottom-info-zone-wrapper-info-title.refresher.online {
  max-width: calc(100vw - 800px);
}

.bottom-info-zone-wrapper-price {
  display: flex;
  align-items: center;
  gap: 24px;
  height: 100%;
}

.bottom-info-zone-wrapper-price-value {
  color: var(--online-color-text);
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.28px;
  white-space: nowrap;
  padding-top: 5px;
}

.bottom-info-zone-wrapper-signup-button {
  display: flex;
  padding: 18px 48px 18px 68px;
  align-items: center;
  gap: 8px;

  height: calc(100% + 2px);

  border-radius: 8px 0px 0px 8px;
  border: 1px solid var(--design-bim-primary-black);
  background: var(--highlight-color);
}

.bottom-info-zone-wrapper-signup-button:hover {
  cursor: pointer;
  background: white;
  transition: background 0.2s ease-in;
}

.bottom-info-zone-wrapper-signup-button-value {
  display: flex;
  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'PT Golos', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  gap: 8px;
}

@media (max-width: 1500px) {
  .bottom-info-zone-wrapper-price {
    justify-content: space-between;
  }
}

@media (max-width: 1000px) {
  .bottom-info-zone-wrapper-info {
    display: none;
  }

  .bottom-info-zone-wrapper-price {
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .bottom-info-zone {
    height: 40px;
  }

  .bottom-info-zone-wrapper {
    padding-left: 24px;
  }

  .bottom-info-zone-wrapper-price-value {
    text-align: right;
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.18px;
    padding-top: 2px;
  }

  .bottom-info-zone-wrapper-signup-button {
    display: flex;
    width: 161px;
    height: 41px;
    padding: 18px 24px;
    align-items: center;
    gap: 8px;
  }

  .bottom-info-zone-wrapper-signup-button-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.32px;
  }
}
