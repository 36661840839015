.card-list-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 54px;
}

.card-list-area.columns-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.card-list-item {
  display: flex;
  gap: 8px;
}

.card-list-item .plus-icon {
  flex-shrink: 0;
}

.card-list-item-text {
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding-top: 2px;
}

.card-list-item-text span {
  color: var(--highlight-color);
}

@media (max-width: 1200px) {
  .card-list-area,
  .card-list-area.columns-3 {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}

@media (max-width: 576px) {
  .card-list-item-text {
    font-size: 16px;
    line-height: 140%;
  }

  .card-list-item .plus-icon {
    max-width: 24px;
  }
}
