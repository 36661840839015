.list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 20px;
}

.list-title {
  color: var(--main-color);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 550;
  line-height: 130%;
  letter-spacing: -0.44px;
  text-transform: uppercase;
  padding-top: 2px;
}

.targets-list-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shift {
  padding-left: 32px;
}

.points-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 16px;
}

.points-list-item {
  display: flex;
  gap: 8px;
}

.points-list-item-text {
  color: var(--design-bim-secondary-black);

  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.point-list-item-arrow.title.refresher.online {

}

@media (max-width: 1200px) {
  .list-title {
    font-size: 24px;
  }

  .points-list-item-text {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .list {
    gap: 8px;
  }

  .list-title {
    font-size: 18px;
  }

  .points-list-item-text {
    font-size: 16px;
  }

  .points-list {
    gap: 12px;
  }

  .shift {
    padding-left: 28px;
  }
}
