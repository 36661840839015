.scroll-up-button {
  opacity: 0;
  position: fixed;
  bottom: 80px;
  left: 20px;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  pointer-events: none;
}

.scroll-up-button.catalog {
  bottom: 23px;
}

.scroll-up-button.shown {
  pointer-events: all;
  opacity: 1;
}

.scroll-up-button:hover {
  cursor: pointer;
}

.scroll-up-button:hover circle {
  fill: white;
  transition: fill 0.3s ease-in-out;
}

.scroll-up-button.move {
  transform: translate(0px, 60px);
}

@media (max-width: 576px) {
  .scroll-up-button {
    bottom: 60px;
  }

  .scroll-up-button.move {
    transform: translate(0px, 40px);
  }
}
