.swiper-work.swiper-comment--modal {
  margin-top: 0;
  padding-left: 72px;
  padding-right: 72px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-work.swiper-comment--modal .swiper-slide {
  width: 100%;
}

.modalMain-wrapper--workUser {
  max-width: 1305px;
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
  height: 100%;
  max-height: 967px;
}

.modalMain-wrapper--workUser .work-slide {
  height: 100%;
}

.modalMain-wrapper--workUser .work-slide-imgBlock {
  height: 100%;
  overflow: hidden;
}

.modalMain-wrapper--workUser .work-slider__img {
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

.modalMain-wrapper--workUser .modal-close {
  top: 86px;
  right: 116px;
  z-index: 2;
}

.swiper-work.swiper-comment--modal .swiper-buttonCont-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.swiper-work.swiper-comment--modal .swiper-buttonCont-button.swiper-buttonCont-prev {
  left: 0;
}

.swiper-work.swiper-comment--modal .swiper-buttonCont-button.swiper-buttonCont-next {
  right: 0;
}

.swiper-work.swiper-comment--modal .swiper-buttonCont {
  justify-content: center;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .swiper-work.swiper-comment--modal {
    padding: 0;
  }

  .modalMain-wrapper--workUser {
    background: white;
  }

  .modalMain-wrapper--workUser .modal-close {
    top: 36px;
    right: 36px;
  }

  .swiper-work.swiper-comment--modal .swiper-buttonCont-button {
    position: static;
    transform: none;
  }

  .swiper-work.swiper-comment--modal .swiper-buttonCont {
    position: absolute;
    z-index: 4;
    bottom: 10px;
    left: 20px;
    background-color: #fff;
    border-radius: 40px;
  }

  .modalMain-wrapper--workUser .work-slide-imgBlock {
    border-radius: 24px;
    max-height: 600px;
    height: 100%;
    /* padding-top: 60px;
    padding-bottom: 60px; */
  }

  .modalMain-wrapper--workUser .swiper-buttonCont-pagination {
    color: var(--design-bim-primary-black);
  }

  .modalMain-wrapper--workUser .swiper-buttonCont-pagination span {
    color: var(--design-bim-primary-black);
  }
}

@media (max-width: 576px) {
  .modalMain-wrapper--workUser .work-slide-imgBlock {
    max-height: 300px;
  }
}
