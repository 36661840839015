.accordion-annotation-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
  cursor: pointer;
}

.accordion-annotation {
  display: flex;
  background: var(--main-color);
  padding: 20px 32px 20px 32px;
  border-radius: 20px;
  justify-content: space-between;
  gap: 16px;
}

.accordion-annotation-left,
.accordion-annotation-right {
  display: flex;
  gap: 8px;
  align-items: center;
}

.accordion-annotation-left-text {
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--online-color-text);
  padding-top: 2px;
}

.accordion-annotation-left-icon {
  flex-shrink: 0;
}

.accordion-annotation-right-text {
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--highlight-color);
  padding-top: 2px;
}

@media (max-width: 768px) {
  .accordion-annotation-left-text {
    font-size: 14px;
  }

  .accordion-annotation-right-text {
    font-size: 14px;
    letter-spacing: -0.28px;
  }

}

@media (max-width: 576px) {
  .accordion-annotation {
    padding-left: 20px;
    padding-right: 20px;
  }

  .accordion-annotation-left-text {
    font-size: 13px;
  }

  .accordion-annotation-right-text {
    display: none;
  }
}
