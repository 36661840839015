.blockBack {
    border-radius: 80px;
    margin: 0 auto;
    max-width: 1636px;
    width: calc(100% - 20px);
}

.blockContent {
    padding: 80px 118px;
    display: grid;
    align-items: end;
    column-gap: 32px;
    grid-template-areas: 'contentHeader contentHeader'
        'testAccessFormBlock testAccessFormBlock'
        'contentDescription contentDescription'
        'contentAdress contentAdress';

}

.contentHeader {
    font-family: ALS Gorizont, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 750;
    letter-spacing: -.4px;
    line-height: 120%;
    grid-area: contentHeader;
}

.contentHeader>span {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: #00CCFF;
}

.contentDescription {
    font-family: PT Golos, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    grid-area: contentDescription;
    margin: 48px 0 24px 0;
}

.contentDescription>p {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
}

.testAccessFormBlock {
    margin-top: 48px;
    grid-area: testAccessFormBlock;
}

.testAccessForm {
    display: grid;
    gap: 16px;
}

.testAccessForm>p {
    font-family: ALS Gorizont, sans-serif;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -.32px;
    line-height: 120%;
    margin-top: 16px;
    padding-top: 2px;
}

.testAccessForm>input {
    border: 2px solid hsla(0, 0%, 5%, .12);
    border-radius: 8px;
    display: flex;
    font-family: PT Golos, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 16px;
}

.buttonBlock {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 24px;
}

.buttonBlock>button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: PT Golos, sans-serif;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background-color: #FFFF33;
    padding: 18px 32px 18px 32px;
}
.buttonBlock>button:hover {
    background: #000000;
    color: #ffffff;
    transition: .2s ;
  }
.buttonBlock>p {
    color: rgba(66, 66, 69, .5);
    font-family: PT Golos, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .14px;
    line-height: 125%;
}

.buttonBlock>p>a {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
}

.contentAdress {
    display: flex;
    justify-content: space-between;
    grid-area: contentAdress;
}

.contentAdress>div {
    display: grid;
    gap: 8px;
}

.contentAdress>div>p {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: PT Golos, sans-serif;
}

.contentAdress>div>p>a,
.contentAdress>div>p>span {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.link_add {
    display: block;
    width: 30px;
    height: 30px;
}

.infoText {
    font-size: 38px;
    font-weight: bold;
    color: #0c3a4d;
}

@media (max-width: 1600px) {}

@media (max-width: 1400px) {

    .blockContent {
        padding: 75px 70px;
    }
}

@media (max-width: 1200px) {
    .blockBack {
        border-radius: 64px;
    }

    .blockContent {
        padding: 64px 40px;
    }

    .contentHeader {
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .blockContent {
        grid-template-areas: 'contentHeader'
            'testAccessFormBlock '
            'contentDescription'
            'contentAdress';
    }

    .testAccessFormBlock {
        margin-top: 24px;
    }

    .testAccessFormBlock {
        width: 100%;
    }

    .contentAdress {
        flex-direction: column-reverse;
        gap: 16px;
    }

    .contentAdress>div {
        gap: 16px;
    }

    .buttonBlock {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .buttonBlock>button {
        height: 56px;
    }

    .contentDescription {
        margin: 24px 0;
    }
}


@media (max-width: 576px) {
    .blockBack {
        border-radius: 40px;
    }

    .blockContent {
        padding: 48px 20px;
    }

    .contentHeader {
        font-size: 22px;
    }

    .contentDescription {
        font-size: 16px;
    }
}