.frame {
  width: 100%;
  max-width: 1656px;
  margin: 0px auto;
  opacity: 0;
  scroll-margin: 78px;
  padding: 0px 10px 0px 10px;
}

.outer-background-margin {
  margin-top: 40px;
}

.outer-background-margin-bottom {
  margin-bottom: 40px;
}

.inner-background-margin {
  padding-top: 40px;
}

/*.frame.refresher {*/
/*  padding: 60px 10px;*/
/*}*/

.frame.with-cover {
  padding: 40px 10px;
}

.frame.plain {
  padding-top: 120px;
}

.frame-cover {
  padding: 80px 118px;
  border-radius: 80px;
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.no-padding {
  padding: 0;
}

/*.frame.no-padding,*/
/*.frame-cover.no-padding {*/
/*  padding: 0;*/
/*}*/

.frame.no-padding-y,
.frame-cover.no-padding-y {
  padding-top: 0;
  padding-bottom: 0;
}

.frame-cover.no-padding-bottom.refresher {
  padding-bottom: 0;
}

.frame.no-padding-top,
.frame-cover.no-padding-top {
  padding-top: 0 !important;
}

.frame.no-padding-x,
.frame-cover.no-padding-x {
  padding-left: 0;
  padding-right: 0;
}

/*.frame.padding-top-60 {*/
/*  padding-top: 60px;*/
/*}*/

#education-stages-frame.frame.bottom-padding.refresher,
#contacts-frame.frame.bottom-padding.refresher {
  padding-bottom: 120px;
}

#courses-frame.frame.with-cover {
  padding-bottom: 80px;
}

#guarantees-frame.frame {
  padding-top: 0
}

/*.frame.padding-top-80 {*/
/*  padding-top: 80px;*/
/*}*/

/*.frame.padding-bottom-80 {*/
/*  padding-bottom: 80px;*/
/*}*/

.padbot-0 {
  padding-bottom: 0;
}

.padtop-0 {
  padding-top: 0;
}

.marginTop-0 {
  margin-top: 0;
}

.inner-frame-cover {
  padding: 16px 32px 0 40px;
  border-radius: 40px;
}

.bor-rad-0 {
  border-radius: 0;
}

.marginBottom-0 {
  margin-bottom: 0;
}

.frameForSlider {
  margin-left: auto;
  padding-right: 0;
}

.no-padding-xRight {
  padding-right: 0;
}

#review-frame.frame {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1600px) {
  #work-frame.frame,
  #review-frame.frame {
    padding-left: 0;
    padding-right: 0;
  }

  .frame-cover {
    padding-left: 100px;
    padding-right: 100px;
  }

  .frame-cover.no-padding {
    padding: 0;
  }

  .padbot-0 {
    padding-bottom: 0;
  }

  .bor-rad-0 {
    border-radius: 0;
  }

  .no-padding-xRight {
    padding-right: 0;
  }

  .frameForSlider {
    padding-left: 10px;
  }
}

@media (max-width: 1400px) {
  .frame-cover {
    padding: 75px 70px;
  }

  .frame.refresher.online {
    padding: 64px 10px;
  }

  .frame-cover.no-padding {
    padding: 0;
  }

  .marginBottom-0 {
    margin-bottom: 0;
  }

  .padbot-0 {
    padding-bottom: 0;
  }

  .bor-rad-0 {
    border-radius: 0;
  }

  .no-padding-xRight {
    padding-right: 0;
  }
}

@media (max-width: 1200px) {
  .frame {
    padding: 0;
  }

  .frame.plain {
    padding-top: 25px;
  }

  .refresher-course .frame {
    margin-top: 0;
    margin-bottom: 0;
  }

  .start-frame {
    padding-top: 90px !important;
  }

  .frame-cover {
    padding: 64px 40px;
    border-radius: 64px;
  }

  #education-stages-frame.frame.bottom-padding.refresher,
  #contacts-frame.frame.bottom-padding.refresher {
    padding-bottom: 64px;
  }

  .inner-frame-cover {
    padding: 16px 32px 40px 40px;
  }

  .marginBottom-0 {
    margin-bottom: 0;
  }

  .padbot-0 {
    padding-bottom: 0;
  }

  .bor-rad-0 {
    border-radius: 0;
  }

  .frame.with-cover {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  #guarantees-frame.frame.with-cover {
    padding-top: 64px;
    padding-bottom: 32px;
  }
}

@media (max-width: 576px) {
  .outer-background-margin {
    margin-top: 20px;
  }

  .outer-background-margin-bottom {
    margin-bottom: 20px;
  }

  #courses-frame.frame.with-cover,
  #education-stages-frame.frame.bottom-padding.refresher {
    padding-bottom: 32px;
  }

  .frame.refresher.online {
    padding: 48px 10px;
  }

  .start-frame {
    padding-top: 72px;
  }

  .frame-cover {
    padding: 48px 20px;
    border-radius: 40px;
  }

  .inner-frame-cover {
    padding: 16px 32px 40px 40px;
  }

  .marginTop-0 {
    margin-top: 0;
  }

  .marginBottom-0 {
    margin-bottom: 0;
  }

  .padbot-0 {
    padding-bottom: 0;
  }

  .bor-rad-0 {
    border-radius: 0;
  }

  .frame.with-cover {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  #targets-frame {
    padding-left: 0;
    padding-right: 0;
  }
}

.bg----design-bim-primary-black {
  background: var(--design-bim-primary-black);
}

.bg----main-offline-color {
  background: var(--main-offline-color);
}

.frame .no-border-radius {
  border-radius: 0;
}

.courses-catalog #courses-frame-all .frame-cover,
.courses-catalog #courses-frame .frame-cover {
  border: 1px solid var(--offline-color);
}
