.frame-cover.author-frame {
  background: var(--main-offline-color);
}

.author-frame-cover-content {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.author-frame-cover-image {
  width: 425px;
  height: 425px;
  position: relative;
  flex-shrink: 0;
}

.author-frame-cover-image2__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  z-index: 1;
  position: relative;
  border: 1px solid var(--design-bim-primary-black);
  filter: grayscale(100);
}

.author-frame-cover-image__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 80px;
  z-index: 1;
  position: relative;
  border: 1px solid var(--design-bim-primary-black);
  filter: grayscale(100);
}

.author-frame-cover-image::before {
  content: '';
  width: 441px;
  height: 441px;
  position: absolute;
  left: -8px;
  top: -8px;
  border-radius: 80px;
  background: var(--tag-color);
}

.author-frame-cover-image2 {
  display: none;
  width: 160px;
  height: 160px;
  position: relative;
  flex-shrink: 0;
}

.author-frame-cover-image2::before {
  content: '';
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  position: absolute;
  left: -8px;
  top: -8px;
  background: var(--tag-color);
  border-radius: 40px;
}

.author-frame-cover-leftSide {
  display: flex;
  gap: 71px;
  align-items: center;
}

.author-info {
  display: flex;
  flex-direction: column;
  color: var(--design-bim-true-white);
  max-width: 740px;
  justify-content: center;
}

.author-info-row {
  display: flex;
  align-items: center;
  gap: 28px;
}

.author-info-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.author-info__name {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.32px;
  font-weight: 750;
  padding-top: 2px;
}

.author-info__prof {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.18px;
  font-weight: 400;
  color: var(--highlight-color);
  padding-top: 2px;
}

.author-info__text {
  margin-top: 16px;
  font-family: "PT Golos", sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.2px;
  font-weight: 400;
  opacity: 0.8;
}

.author-frame-cover-bg {
  display: flex;
  align-items: flex-end;
  width: 25%;
  margin-right: -1px;
}

.author-info__link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none;
  color: var(--highlight-color);

}

.author-info__link span {
  position: relative;
}

.author-info__link span::after {
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -4px;
  opacity: 0.4;
  background: var(--highlight-color);
}

.author-info__link span:hover::after {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

@media (max-width: 1600px) {
  .author-frame-cover-bg {
    width: 20%;
    margin-bottom: -30px;
  }
}

@media (max-width: 1400px) {
  .author-frame-cover-bg {
    display: none;
  }

  .author-frame-cover-leftSide {
    padding-right: 20px;
  }
}

@media (max-width: 1200px) {
  .author-frame-cover-image {
    display: none;
  }

  .author-frame-cover-image2 {
    display: flex;
  }

  .author-frame-cover-content {
    padding: 64px 40px;
  }

  .author-frame-cover-leftSide {
    padding-right: 0;
  }

  .author-info {
    max-width: unset;
  }

  .author-info__name {
    font-size: 36px;
    letter-spacing: -0.36px;
  }

  .author-info__prof {
    font-size: 20px;
    letter-spacing: normal;
  }

  .author-info__text {
    margin-top: 24px;
    margin-bottom: 24px;
    letter-spacing: normal;
  }

  .author-info__link {
    font-size: 20px;
    letter-spacing: -0.2px;
  }
}

@media (max-width: 576px) {
  .author-frame-cover-content {
    padding: 48px 20px;
  }

  .author-info-row {
    align-items: flex-start;
  }

  .author-frame-cover-image2 {
    width: 100%;
    max-width: 320px;
    height: 320px;
  }

  .author-info-row {
    flex-direction: column;
  }

  .author-info__name {
    font-size: 22px;
    letter-spacing: -0.22px;
  }

  .author-info__prof {
    font-size: 16px;
    line-height: 140%;
  }

  .author-info__text {
    margin-top: 16px;
    font-size: 16px;
    margin-bottom: 28px;
  }

  .author-info__link {
    font-size: 16px;
    letter-spacing: normal;
  }
}
