.format-selector-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 40px 0 40px;
}

.format-selector {
  display: flex;
  width: calc(100% - 10px);
  gap: 54px;
  max-width: 1550px;
  height: 36px;
  border-bottom: 1px solid #C9D4EB;
}

.format-selector-option {
  position: relative;
  color: var(--design-bim-tertiary-black, #656D7C);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.14px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  padding-top: 2px;
}

.format-selector-option.selected,
.format-selector-option:hover {
  color: var(--main-color);
  transition: color 0.2s ease-in;
}

.format-selector-option::before {
  content: '';
}

.format-selector-option.selected::before,
.format-selector-option:hover::before {
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  bottom: -1px;
  background: var(--main-color);
  z-index: 2;
  transition: background 0.2s ease-in;
}

@media (max-width: 576px) {
  .format-selector-wrapper {
    padding: 0 20px 0 20px;
    justify-content: unset;
  }

  .format-selector-option {
    font-size: 13px;
  }

  .format-selector {
    gap: 36px;
  }
}

@media (max-width: 360px) {
  .format-selector-wrapper {
    padding: 0 20px 0 20px;
    justify-content: unset;
  }

  .format-selector-option {
    font-size: 13px;
  }

  .format-selector {
    gap: 36px;
  }
}

@media (max-width: 450px) {
  .format-selector {
    justify-content: space-between;
  }
}
