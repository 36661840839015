.swiper-comment {
  margin-top: 38px;
}

.comment-slider {
  width: 100%;
  border-radius: 24px;
  border: 1px solid var(--design-bim-primary-black);
  background: var(--design-bim-true-white);
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 40px 28px;
  height: 100%;
}

.swiper-comment .swiper-slide {
  width: calc(50% - 8px);
}

.comment-slider-img {
  width: 96px;
  flex-shrink: 0;
}

.comment-image {
  width: 100%;
  height: 100%;
  border-radius: 80px;
  border: 1px solid var(--design-bim-primary-black);
}

.comment-slider-info {
  display: flex;
  flex-direction: column;
}

.comment-slider-info__authorName {
  font-family: "ALS Gorizont", sans-serif;
  padding-top: 2px;
  font-size: 24px;
  font-weight: 550;
  line-height: 110%;
  letter-spacing: -0.48px;
  color: var(--design-bim-primary-black);
  margin-bottom: 8px;
}

.comment-sldier-info__text {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  color: var(--design-bim-secondary-black);
  margin-bottom: 24px;
}

.comment-showFullComment {
  font-family: "PT Golos", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: var(--main-color);
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: var(--lighten-main-color);
}

.comment-showFullComment:hover {
  cursor: pointer;
  text-decoration-color: var(--main-color);
}

.review-frame .title-area-text {
  color: var(--online-color-text);
}

@media (max-width: 1600px) {
  .frame-cover.review-frame {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-comment {
    padding-left: 100px;
    padding-right: 100px;
  }

  .review-frame .title-area-text {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 1400px) {
  .swiper-comment {
    padding-left: 70px;
    padding-right: 70px;
  }

  .review-frame .title-area-text {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: 1200px) {
  .swiper-comment .swiper-slide {
    width: 100%;
  }

  .swiper-comment {
    padding-left: 40px;
    padding-right: 40px;
  }

  .comment-sldier-info__text {
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .comment-slider-info__authorName {
    font-size: 28px;
    letter-spacing: -0.56px;
  }

  .review-frame .title-area-text {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .swiper-comment .swiper-slide {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .review-frame .title-area-text {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-comment {
    margin-top: 24px;
    padding: 0;
  }

  .frame-cover.review-frame {
    padding: 48px 20px;
  }

  .comment-slider {
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    gap: 12px;
  }

  .comment-sldier-info__text {
    font-size: 16px;
    letter-spacing: initial;
    margin-bottom: 16px;
  }

  .comment-slider-info__authorName {
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .comment-slider-img {
    width: 80px;
  }
}
