.guarantees-frame-content {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.gf-info-area {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.gf-cert-frame-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

#gf-cert-frame .title-area-text {
  color: var(--online-color-text);
}

.gf-cert-frame-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  gap: 24px;
}

.gf-cert-frame-content-header svg path {
  fill: var(--online-color-text);
}

.gf-cert-frame-content-header svg circle,
.gf-cert-frame-content-header svg rect {
  stroke: var(--online-color-text);
}

.gf-cert-frame-content * #options-icon {
  fill: var(--online-color-text);
  stroke: none;
}

.gf-cert-frame-content-line {
  height: 1px;
  flex-grow: 2;
  background: var(--online-color-text);
}

.gf-cert-frame-content-body {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.gf-cb-cert {
  display: flex;
  align-items: flex-end;
  min-width: 580px;
}

.gf-cb-cert img {
  width: 100%;
}

.gf-cb-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.gf-cert-show-button {
  display: flex;
  color: var(--highlight-color);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}

.gf-cert-show-button:hover {
  cursor: pointer;
}

.gf-cert-show-button span {
  position: relative;
}

.gf-cert-show-button span::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 100%;
  bottom: -4px;
  background: var(--highlight-color);
  left: 0;
}

.gf-cert-show-button:hover span::after {
  background: var(--highlight-color);
}

.gf-cert-bim-logo {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  width: 100%;
  max-width: 798px;
}

.inner-frame-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 550;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.32px;
}

.extended-guarantees-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.extended-guarantee-area {
  display: flex;
  gap: 16px;
}

.extended-guarantee-area .plus-icon {
  flex-shrink: 0;
}

.extended-guarantee-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.extended-guarantee-text {
  color: var(--design-bim-true-white, #FFF);
  font-family: "ALS Gorizont", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 2px;
}

.extended-guarantee-text span {
  color: var(--highlight-color);
}

.extended-guarantee-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.extended-guarantee-tag {
  color: var(--design-bim-true-white, #FFF);
  font-family: "PT Golos", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 19.5px */

  border-radius: 40px;
  padding: 12px 14px;
  background: var(--tag-color);
}

@media (max-width: 1200px) {
  .gf-cert-frame-content-body {
    flex-direction: column;
  }

  .gf-cert-bim-logo {
    display: none;
  }

  .gf-cb-cert {
    min-width: 300px;
  }

  .gf-info-area {
    gap: 48px;
  }

  .extended-guarantees-area {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .guarantees-frame-content {
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .extended-guarantees-area {
    gap: 32px;
  }
}

@media (max-width: 576px) {
  .extended-guarantee-area .plus-icon {
    max-width: 24px;
  }

  .gf-cert-frame-content-body {
    gap: 24px;
  }

  .gf-cert-bim-logo {
    display: none;
  }

  .inner-frame-title {
    font-size: 26px;
  }

  .gf-cert-show-button {
    font-size: 16px;
  }

  .gf-cb-cert {
    min-width: unset;
  }

  .guarantees-frame-content {
    gap: 32px;
  }

  .guarantees-frame-content .inner-frame-cover {
    padding: 20px;
  }

  .guarantees-frame-content .inner-frame-title {
    font-size: 20px;
  }

  .gf-cb-info {
    gap: 12px;
  }

  .gf-info-area {
    gap: 32px;
  }

  .gf-cert-frame-content {
    gap: 20px;
  }

  .extended-guarantee-text {
    font-size: 16px;
  }

  .extended-guarantee-tag {
    font-size: 13px;
  }
}
