.teachers-frame-area {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.teachers-frame-title {
  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 550;
  line-height: 130%;
  padding-top: 2px;
}

.teachers-frame-title span {
  color: var(--main-color);
}

.teacher-tooltip-wrapper {
  background: none !important;
  min-width: 500px;
}

.teacher-tooltip {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  margin-top: -5px;
  gap: 8px;
  border-radius: 24px;
  background: var(--main-color);
  border: 1px solid black;
}

.tippy-arrow::before {
  display: none;
}

.teacher-tooltip-name {
  color: white;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.48px;
  padding-top: 2px;
}

.teacher-tooltip-info {
  color: white;
  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.teachers-frame-teachers {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  gap: 32px;
}

.teachers-frame-teachers img {
  border-radius: 120px;
  border: 2px solid var(--design-bim-tertiary-black, #656D7C);
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  filter: grayscale(100);
}

.teachers-frame-teachers img:hover {
  filter: grayscale(0);
  transition: filter 0.2s ease-in;
  border-color: var(--main-color);
}

.teachers-frame-teachers .teachers-frame-teacher_block:nth-child(5n+1) {
  justify-self: flex-start;

}

.teachers-frame-teachers .teachers-frame-teacher_block:nth-child(5n+5) {
  justify-self: flex-end;
}

.teachers-frame-teacher_block {
  display: flex;
  position: relative;
  height: 200px;
}

@media (max-width: 1400px) {
  .teachers-frame-teachers {
    gap: 0;
  }
}

@media (max-width: 1200px) {
  .teachers-frame-teachers img {
    position: absolute;
    left: -5%;
  }

  .teachers-frame-teacher_block {
    justify-self: flex-start;
  }

  .teachers-frame-teachers .teachers-frame-teacher_block:nth-child(5n+5) {
    justify-self: flex-start;
  }
}

@media (max-width: 1024px) {
  .teachers-frame-teacher_block {
    height: 140px;
  }

  .teachers-frame-teachers img {
    width: 140px;
    height: 140px;
  }

  .teacher-tooltip-wrapper {
    min-width: 400px;
  }
}

@media (max-width: 768px) {
  .teachers-frame-teacher_block {
    height: 100px;
  }

  .teachers-frame-teachers img {
    width: 100px;
    height: 100px;
  }

  .teachers-frame-title {
    font-size: 36px;
  }

  .teacher-tooltip-wrapper {
    min-width: 300px;
  }
}

@media (max-width: 576px) {
  .teachers-frame-teacher_block {
    height: 80px;
  }

  .teachers-frame-teachers img {
    width: 80px;
    height: 80px;
  }

  .teachers-frame-title {
    font-size: 20px;
  }
}

@media (max-width: 420px) {
  .teachers-frame-teacher_block {
    height: 62px;
  }

  .teachers-frame-teachers img {
    width: 62px;
    height: 62px;
  }
}
