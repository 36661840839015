.footer {
  /* padding-top: 40px;
  padding-bottom: 60px; */
  background: var(--main-offline-color);
}

.footer-wrapper-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 40px;

}

.footer-wrapper-2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 40px;
  border-top: 1px solid rgba(201, 212, 235, 0.2);
}

/* .footer-wrapper-2::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background: rgba(201, 212, 235, 0.2);
} */

.fw-area-1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
}

.fw-credits {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fw-credits-description {
  color: rgba(255, 255, 255, 0.60);
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.14px;
  max-width: 320px;
}

.fw-credits-logo {
  width: 144px;
}

.fw-area-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  /* padding: 16px 0 16px 0; */
  gap: 2px;
}

.fw-license-info {
  color: rgba(255, 255, 255, 0.60);
  font-family: 'PT Golos', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 15px */
  text-decoration: none;
}

.fw-license-info:hover {
  text-decoration: underline;
}

.fw-rules-info {
  display: flex;
  gap: 8px;
}

.fw-ri-1, .fw-ri-2 {
  position: relative;
  color: var(--design-bim-true-white);
  font-family: 'PT Golos', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 15px */
  text-decoration: none;
}

.fw-ri-1 span, .fw-ri-2 span {
  position: relative;
}

.fw-ri-1 span::after, .fw-ri-2 span::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background: rgba(201, 212, 235, 0.2);
  left: 0;
  bottom: -2px;
}

.fw-ri-1:hover, .fw-ri-2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.vk-link {
  display: flex;
  align-items: flex-start;
}

.vk-link:hover svg path {
  fill: var(--main-color);
  transition: fill 0.3s ease-in-out;
}

.vk-link svg rect {
  fill: var(--background-color);
}

@media (max-width: 576px) {
  .footer-wrapper-1 {
    padding: 32px 20px;
  }

  .footer-wrapper-2 {
    padding: 16px 20px;
  }

  .fw-area-1 {
    flex-direction: column;
    gap: 16px;
  }

  .fw-area-2 {
    gap: 4px;
  }

  .fw-license-info, .fw-ri-1, .fw-ri-2 {
    font-size: 11px;
  }

  .fw-rules-info {
    flex-direction: column;
    gap: 4px;
  }

  .footer {
    padding-bottom: 0px;
    padding-top: 0;
  }
}
