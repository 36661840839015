.swiper-comment.swiper-comment--modal {
  margin-top: 0;
  padding-left: 72px;
  padding-right: 72px;
  height: 100%;

  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-comment.swiper-comment--modal .swiper-slide {
  width: 100%;
}

.swiper-comment.swiper-comment--modal .comment-slider {

  flex-direction: column;
  padding: 80px 40px;
  gap: 32px;
  border-radius: 40px;
}

.swiper-comment.swiper-comment--modal .comment-sldier-info__text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0;
  /* max-height: 380px; */
  overflow-y: auto;

  max-height: calc(100% - 100px);
  padding-right: 10px;
  font-size: 20px;
}

.swiper-comment.swiper-comment--modal .rating-comment {
  margin-bottom: 0;
}

.comment-slider-imgRow {
  display: flex;
  align-items: center;
  gap: 24px;
}

.swiper-comment.swiper-comment--modal .swiper-buttonCont-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.swiper-comment.swiper-comment--modal .swiper-buttonCont-button.swiper-buttonCont-prev {
  left: 0;
}

.swiper-comment.swiper-comment--modal .swiper-buttonCont-button.swiper-buttonCont-next {
  right: 0;
}

.modalMain-wrapper--comment .modal-close {
  right: 116px;
  z-index: 2;
  top: 86px;
}

.swiper-comment.swiper-comment--modal .swiper-buttonCont {
  justify-content: center;
  margin-top: 16px;
}

@media (max-width: 1200px) {
  .swiper-comment.swiper-comment--modal .comment-slider {
    padding: 64px 32px;
    gap: 24px;
  }

}

@media (max-width: 768px) {
  .swiper-comment.swiper-comment--modal .comment-sldier-info__text {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .comment-slider-imgRow {
    padding-right: 45px;
  }

  .modalMain-wrapper--comment {
    max-height: unset;
  }

  .swiper-comment.swiper-comment--modal .comment-slider {
    padding: 20px 20px 86px 20px;
    gap: 16px;
    border-radius: 24px;
  }

  .modalMain-wrapper--comment .modal-close {
    top: 36px;
    right: 36px;
  }

  .swiper-comment.swiper-comment--modal .swiper-buttonCont-button {
    position: absolute;
    transform: unset;
    bottom: 10px;
    top: unset;

  }

  /* .swiper-comment.swiper-comment--modal .swiper-buttonCont-button svg{
      width: 35px;
      height: 35px;
  } */
  .swiper-comment.swiper-comment--modal {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .swiper-comment.swiper-comment--modal .swiper-buttonCont-button {
    position: static;
    transform: none;
  }

  .swiper-comment.swiper-comment--modal .swiper-buttonCont {
    position: absolute;
    z-index: 4;
    bottom: 10px;
    left: 20px;
    background-color: #fff;
    border-radius: 40px;
  }

  .comment-slider-imgRow {
    gap: 12px;
  }

  .swiper-comment.swiper-comment--modal .swiper-buttonCont-pagination {
    color: var(--design-bim-primary-black);
  }

  .swiper-comment.swiper-comment--modal .swiper-buttonCont-pagination span {
    color: var(--design-bim-primary-black);
  }
}

@media (max-width: 576px) {
  .swiper-comment.swiper-comment--modal .comment-sldier-info__text {
    font-size: 16px;
  }
}
