.frame-cover.schedule-frame {
  background: var(--main-offline-color);
}

.schedule-frame-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}

.schedule-label {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 120px;
}

.schedule-label--green {

  background: var(--highlight-color);
}

.schedule-label--main {
  padding: 8px 14px;
}

.schedule-label--main .schedule-label__text {
  color: var(--design-bim-primary-black);
  font-family: "ALS Gorizont";
  font-size: 24px;
  font-weight: 400;
  padding-top: 5px;
}

.schedule-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
}

.schedule-wrapper-timetable {
  display: grid;
  column-gap: 104px;
  row-gap: 40px;
  grid-template-columns: repeat(4, 1fr);

}

.schedule_dayBlock__text {
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  background: var(--second-highlight-color);
}

.schedule_dayBlock__text-content {
  color: #000;
  font-size: 14px;
  font-weight: 550;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.28px;
  text-transform: uppercase;
  flex-shrink: 0;
  padding-top: 2px;
}

.schedule_dayBlock {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: "ALS Gorizont";
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  color: var(--brand-itmo-white);
  position: relative;
}

.schedule_dayBlock::after {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url(/public/imgStatic/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(100%, -50%);
}

.schedule_dayBlock:nth-child(4n)::after, .schedule_dayBlock:last-child::after {
  display: none;
}

.schedule-footer {
  padding-top: 40px;
  border-top: 1px solid #656D7C;
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.schedule-footer-time, .schedule-footer-address {
  display: flex;
  align-items: center;
  gap: 8px;
}

.schedule-footer-time svg, .schedule-footer-address svg {
  flex-shrink: 0;
}

.schedule-footer-time-block, .schedule-footer-address-block {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--brand-itmo-white);
  font-family: "PT Golos", sans-serif;
  font-size: 16px;
  line-height: 140%;
}

.schedule-footer-time-block__text {
  font-weight: 600;
}

.schedule-footer-addressBlock {
  display: flex;
  align-items: center;
  gap: 4px;
}

.schedule-footer-address-block__text {
  font-weight: 600;

}

.schedule-footer-address-block__text:hover {
  cursor: pointer;
}

@media (max-width: 1300px) {
  .schedule-wrapper-timetable {
    column-gap: 74px;
  }

  .schedule_dayBlock::after {
    right: -25px;
  }
}

@media (max-width: 1200px) {
  .schedule-wrapper-timetable {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 104px;
  }

  .schedule_dayBlock:nth-child(4n)::after {
    display: block;
  }

  .schedule_dayBlock:nth-child(3n)::after, .schedule_dayBlock:last-child::after {
    display: none;
  }

  .schedule_dayBlock::after {
    right: -40px;
  }

  .schedule-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 1024px) {
  .schedule-wrapper-timetable {
    grid-template-columns: repeat(2, 1fr);
  }

  .schedule_dayBlock:nth-child(3n)::after {
    display: block;
  }

  .schedule_dayBlock:nth-child(2n)::after, .schedule_dayBlock:last-child::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .schedule-footer-address, .schedule-footer-address-block {
    align-items: flex-start;
  }

  .schedule-footer-addressBlock {
    display: inline;
  }

  .schedule-footer-address-block svg {
    position: relative;
    top: 5px;
    left: 4px;
  }

  .schedule-label--main .schedule-label__text {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .schedule-frame-content {
    gap: 32px;
  }

  .schedule-wrapper-timetable {
    column-gap: 36px;
    row-gap: 16px;
  }

  .schedule_dayBlock::after {
    right: -8px;
  }

  .schedule-wrapper {
    gap: 32px;
  }

  .schedule-footer {
    gap: 24px;
    padding-top: 32px;
  }

  .schedule_dayBlock {
    gap: 8px;
    font-size: 14px;
  }

  .schedule_dayBlock__text {
    width: 28px;
    height: 28px;
  }

  .schedule-footer-time-block, .schedule-footer-address-block {
    font-size: 16px;
  }

  .schedule-label--main .schedule-label__text {
    font-size: 16px;
  }

  .schedule-label--main {
    padding: 6px 16px 8px 20px;
    gap: 6px;
    flex-wrap: wrap;
  }
}
