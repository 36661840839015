.all-courses {
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  top: 78px;
  right: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 78px);
  z-index: 2;
  background: var(--design-bim-true-white);
  flex-direction: column;
}

.all-courses-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.all-course-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
  gap: 20px;
}

.all-course-item-title-area {
  display: flex;
  gap: 8px;
}

.all-courses-masonry > div {
  /*gap: 64px !important;*/
}

.all-course-item-title {
  color: var(--design-bim-primary-black, #0D0D0D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 2px;
}

.all-course-points {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.all-course-points-courses-type {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.all-course-points-courses-type-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.all-course-points-title {
  color: var(--lighten-main-color);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.13px;
  text-transform: uppercase;
  padding-top: 2px;
}

.all-course-points-item {
  color: var(--main-color);
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  text-decoration: none;
  /* display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap; */
}

.all-course-points-item span {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: var(--lighten-main-color);
}

.all-course-points-item:hover span {
  text-decoration-color: var(--main-color);
  cursor: pointer;
}

.all-courses-arrowBack {
  display: none;
}

.all-courses-masonry {
  max-width: 1620px;
  gap: 80px !important;
  padding: 0 40px;
}

.all-course-points-item__label {
  padding: 2px 8px;
  border-radius: 40px;
  border: 1px solid var(--design-bim-tertiary-black, #656D7C);
  color: var(--design-bim-secondary-black);
  font-size: 12px;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  display: inline;
  margin-left: 8px;
  white-space: nowrap;
  text-decoration: none;
}

.all-course-points-item--ml {
  line-height: 160%;
}

.all-courses-closeIcon {
  display: flex;
  justify-content: center;
  padding: 24px 40px 0px 40px;
}

.all-courses-closeIcon-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 1550px;
  width: 100%;
  gap: 30px;
}

@media (max-width: 1400px) {
  .all-courses-closeIcon {
    display: none;
  }

  .all-courses-arrowBack {
    display: flex;

    padding: 24px 40px 24px 40px;
  }

  .all-courses-wrapper {
    padding-top: 40px;
  }
}

@media (max-width: 1200px) {
  .all-courses-masonry {
    flex-direction: column !important;
    place-content: initial !important;
  }

  .all-courses-masonry > div {
    width: 100% !important;
  }

  .all-courses-masonry {
    gap: 0 !important;
  }

  .all-course-points {
    gap: 16px;
  }
}

@media (max-width: 576px) {
  .all-courses-arrowBack {
    padding: 24px 20px 24px 20px;
  }

  .all-courses-wrapper {
    padding-top: 24px;
  }

  .all-courses {
    height: calc(var(--vh, 1vh) * 100 - 72px);
    top: 72px;
  }

  .all-courses-masonry {
    padding: 0 20px;
  }

  .all-course-item-title-area {
  }

  .all-course-item-title-area img {
    width: 32px;
    height: 32px;
  }

  .all-course-item {
    padding-bottom: 56px;
  }

  .all-courses-masonry > div {
  }

  .all-courses-masonry {
    gap: 0 !important;
  }

  .all-course-points {
    gap: 12px;
  }

  .all-course-item {
    gap: 16px;
  }

  .all-course-item-title {
    font-size: 20px;
  }

  .all-course-points-item {
    font-size: 16px;
  }
}
