.education-process-refresh {
  /*padding-top: 80px;*/
  /*padding-bottom: 80px;*/
}

.educ-process-sliderRefresher__text {
  color: var(--design-bim-true-white);
  font-family: "ALS Gorizont";
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
  gap: 15px;
}

.educ-process-sliderRefresher__text span {
  color: var(--highlight-color);
}

.educ-process-sliderRefresher {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}

.educ-process-sliderRefresher-block {
  max-width: 810px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.educ-process-sliderRefresher-block .educ-process-sliderRefresher__text,
.educ-process-sliderRefresher-block .title-area-text {
  color: var(--online-color-text);
}

.educ-process-sliderRefresher-block-img {
  display: flex;
  width: 40%;
  overflow: hidden;
}

.educ-process-sliderRefresher-block-img img {
  max-height: 500px;
}

.swiper-buttonCont-refresher {
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 40% - 51px);
  width: 100%;
  margin-top: 32px;
}

.swiper-buttonCont-refresher-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.swiper-buttonCont-refresher .swiper-buttonCont {
  margin-top: 0;
}

.educ-process-sliderRefresher-block .swiper-buttonCont-refresher-block {
  display: none;
}

@media (max-width: 1400px) {
  .educ-process-sliderRefresher {
    gap: 30px;
  }

  .swiper-buttonCont-refresher {
    max-width: calc(100% - 30% - 30px);
  }

  .educ-process-sliderRefresher-block-img {
    width: 30%;
  }
}

@media (max-width: 1200px) {
  .educ-process-sliderRefresher {
    flex-direction: column;
    align-items: center;
    gap: 64px;
  }

  .educ-process-sliderRefresher-block-img {
    width: 100%;
    justify-content: center;
  }

  .educ-process-sliderRefresher-block {
    max-width: 100%;
  }

  .education-process-refresh {
    /*padding-top: 32px;*/
    /*padding-bottom: 32px;*/
  }

  .educ-process-sliderRefresher-block {
    gap: 40px;
  }

  .swiper-buttonCont-refresher {
    position: static;
    margin-top: unset;
  }

  .swiper-buttonCont-refresher .swiper-buttonCont-refresher-block {
    display: none;
  }

  /* */
  .educ-process-sliderRefresher-block .swiper-buttonCont-refresher-block {
    display: flex;
  }

  .educ-process-sliderRefresher-block .swiper-buttonCont-refresher-block button {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .educ-process-sliderRefresher-block-img img {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .educ-process-sliderRefresher__text {
    font-size: 16px;
  }

  .educ-process-sliderRefresher-block .swiper-buttonCont-refresher-block {
    flex-direction: column;
    gap: 16px;
  }

  .educ-process-sliderRefresher-block {
    gap: 24px;
  }

  .educ-process-sliderRefresher {
    gap: 24px;
  }
}
