.refresher-course {
  background-color: var(--background-color);
  /* user-select: none; */
  width: calc(100vw - 10px);
}

.refresher-course-bg--first {
  background: var(--main-color);
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media (max-width: 1200px) {
  .refresher-course-bg--first {
    background-image: none !important;
  }
}

@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .refresher-course {
    width: 100%;
  }
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .all-courses,
body.modal-open .modalMenu {
  width: calc(100% + 10px);
  margin-right: -10px;
}
