.calendar-area {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--highlight-color);
  margin-right: 24px;
}

.calendar-area-date {
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

@media (max-width: 576px) {
  .calendar-area {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
