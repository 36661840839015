.info-frame-title-tag {
  display: flex;
}

.info-frame-title-tag-wrapper {
  display: flex;
  padding: 12px 16px;
  border-radius: 24px;
  background: var(--highlight-color);
  gap: 10px;
}

.info-frame-title-tag-text {
  display: flex;
  align-items: center;
  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 46px */
  letter-spacing: -0.4px;
  text-transform: uppercase;
  padding-top: 5px;
}


@media (max-width: 1200px) {
  .info-frame-title-tag-text {
    font-size: 30px;
    line-height: 115%;
  }

  .info-frame-title-tag-wrapper {
    padding: 7px 10px;
    gap: 7px;
  }
}

@media (max-width: 576px) {
  .info-frame-title-tag-text {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 0.22px;
  }

  .info-frame-title-tag-wrapper {
    padding: 9px 10px;
    gap: 7px;
  }
}
