.message-area {
  display: flex;
  align-items: center;
  gap: 8px;
}

.message {
  opacity: 0;
  font-size: 21px;
  font-family: 'ALS Gorizont', sans-serif;
  color: var(--brand-itmo-white);
  padding-top: 2px;
}

.message-icon {
  opacity: 0;
  width: 24px;
  height: 24px; 
}

.splash-screen {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: var(--main-color);
  padding: 0;
  margin: 0;
}

.splash-screen-logo-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-screen.animated {
  background: none;
  transition: background 0.5s ease-in-out;
}

.splash-screen.failed {
  background: #9f1031;
  transition: background 0.5s ease-in-out;
}

.splash-screen.failed .loader {
  display: none;
}

.splash-screen.failed .message {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.splash-screen.failed .message-icon {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.splash-screen.animated-exit {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  width: calc(100vw - 10px);
  padding-left: 10px;
  display: none;
}

@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .splash-screen.animated-exit {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    width: calc(100vw);
    padding-left: 0px;
  }
}

.splash-screen.not-visible {
  display: none;
}

.splash-screen-logo {
  width: 10vw;
}

.splash-screen-logo * {
  fill: var(--main-text-color);
}

.loader {
  border-radius: 50%;
  border-top: 3px solid var(--main-text-color);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader.splash-screen--loader {
  width: 30px;
  height: 30px;
}

.loader.splash-screen--button {
  width: 15px;
  height: 15px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1400px) {
  .splash-screen-logo {
    width: 15vw;
  }
}

@media (max-width: 768px) {
  .loader.splash-screen--loader {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 576px) {
  .loader.splash-screen--loader {
    width: 24px;
    height: 24px;
  }

  .message-icon {
    width: 18px;
    height: 18px;
  }

  .message {
    font-size: 14px;
  }

  .splash-screen-logo {
    width: 40vw;
  }
}
