.accordionList {
  display: flex;
  flex-direction: column;
  border: 1px solid #656D7C;
  border-radius: 40px;
}

.accordion-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #656D7C;
  scroll-margin: 100px;
}

.accordion-title {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: var(--design-bim-true-white);
  gap: 64px;
  justify-content: space-between;
  min-height: 128px;
}

.accordion-title.first {
  border-radius: 28px 28px 0 0;
}

.accordion-title.last {
  border-radius: 0 0 28px 28px;
}

.accordion-item.last {
  border-bottom: none;
}

.accordion-title.accordion-title--active {
  background: transparent;
}

.accordion-title-symbol {
  width: 56px;
  height: 56px;
  flex-shrink: 0;
}

.accordion-title.accordion-title--active .accordion-title-symbol {
  border-color: #fff;
  color: #fff;
}

.accordion-title-block {
  display: flex;
  align-items: center;
  gap: 26px;
}

.accordion-title-block__count {
  font-family: "ALS Gorizont", sans-serif;
  font-weight: 100;
  width: 44px;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.64px;
  color: var(--design-bim-primary-black);
  flex-shrink: 0;
  padding-top: 2px;
}

.accordion-title-block__title {
  font-family: "ALS Gorizont", sans-serif;
  font-weight: 550;
  font-size: 22px;
  line-height: 130%;
  color: var(--design-bim-primary-black);
  padding-top: 2px;
}

.accordion-item:hover .accordion-title.accordion-title--active .accordion-title-block__title,
.accordion-title.accordion-title--active .accordion-title-block__title,
.accordion-title.accordion-title--active .accordion-title-block__count {
  color: var(--highlight-color);
}

.accordion-content {
  padding: 8px 96px 48px 96px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.accordion-content.question {
  padding: 8px 24px 48px 24px;
}

.accordion-content__title {
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 140%;
}

.accordion-content-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.accordion-content-list__item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  color: #fff;
}

.accordion-content__text {
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  line-height: 140%;
  color: #fff;
}

.accordion-item:hover .accordion-title {
  background: transparent;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
}

.accordion-item:hover .accordion-title-block__title, .accordion-item:hover .accordion-title-block__count {
  color: var(--highlight-color);
  transition: color 0.2s ease-in;
}

.accordion-item:hover .accordion-title-symbol circle {
  fill: var(--highlight-color);
  stroke: #0D0D0D;
  transition: fill 0.2s ease-in;
}

.accordion-item:hover .accordion-title-symbol path {
  stroke: #0D0D0D;
}

.acl-points-title {
  color: var(--design-bim-true-white);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
}

@media (max-width: 1200px) {
  .accordion-title-block {
    gap: 20px;
  }

  .accordion-title-block__count {
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.56px;
  }
}

@media (max-width: 576px) {
  /* .accordion-annotation {
    flex-direction: column;
  } */

  /*.accordionList {*/
  /*  padding-right: 20px;*/
  /*  padding-left: 20px;*/
  /*}*/

  .accordionList .accordion-item:first-child {
    border-radius: 24px 24px 0 0;
  }

  .accordionList .accordion-item:last-child {
    border-radius: 0 0 24px 24px;

  }

  .accordion-title-block {
    gap: 12px;
    align-items: center;
  }

  .accordion-title-symbol {
    width: 32px;
    height: 32px;
    margin-top: 12px;
    align-self: flex-start;
  }

  .accordion-title-symbol svg {
    width: 100%;
    height: 100%;
  }

  .accordionList .accordion-item:first-child .accordion-title-block__count {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .accordion-title-block__count {
    font-size: 18px;
    letter-spacing: -0.36px;
    width: 32px;
    text-align: center;
  }

  .accordion-title {
    gap: 12px;
    padding: 12px;
    min-height: 80px;
    align-items: center;
  }

  .accordion-title-block__title {
    font-size: 15px;
    line-height: 120%;
  }

  .accordion-content {
    padding: 4px 20px 32px 20px;
  }

  .accordion-content-list__item, .accordion-content__text {
    font-size: 16px;
  }

  .program-frame-all {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.16px;
  }

  .program-frame {
    padding: 48px 20px;
  }

  .accordion-content__title {
    display: none;
  }

  .accordion-content-list__item {
    align-items: flex-start;
  }

  .accordion-content.question {
    padding-bottom: 32px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .faq-frame-wrapper .accordion-title {
    padding-left: 20px;
  }
}
