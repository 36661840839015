.info-tags {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.info-tag {
  display: flex;
  gap: 12px;
}

.info-tag-icon {
  flex-shrink: 0;
  margin-top: 3px;
}

.info-tag-text {
  color: var(--main-text-color);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: 140%; /* 25.2px */
  text-transform: uppercase;
  padding-top: 2px;
}

.info-tag-text span {
  color: var(--highlight-color);
}

@media (max-width: 1200px) {
  .info-tags {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .info-tag-text {
    font-size: 17px;
  }
}

@media (max-width: 576px) {
  .info-tags {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .info-tag-text {
    font-size: 16px;
  }
}
