.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
  border-radius: 12px;
  outline: none;
  text-decoration: none;
  padding: 17px 23px 17px 27px;
  transition: background 0.2s ease-in;
}

.button:hover {
  cursor: pointer;
}

.button--blue {
  color: var(--online-color-text);
  background: var(--main-color);
  border-color: var(--offline-color);
}

.button--blue:hover {
  background: var(--offline-color);
  color: var(--offline-color-text);
  border-color: var(--offline-color);
}

.button--white {
  background: var(--gray-blue-25-bg-hover);
  color: var(--design-bim-primary-black);
  border-color: var(--gray-blue-25-bg-hover);
}

.button--white:hover {
  filter: brightness(0.85);
  transition: filter 0.2s ease-in;
}

.button--green {
  color: var(--design-bim-primary-black);
  background: var(--highlight-color);
  border-color: var(--design-bim-primary-black);
}

.button--green:hover {
  background: var(--offline-color);
  border: 1px solid var(--offline-color-text);
  transition: background 0.2s ease-in;
}

.button--transparent {
  border: 1px solid var(--online-color-text);
  background-color: transparent;
  color: var(--online-color-text);
}

.firstScreen.refresher.online .button--transparent {
  border-color: var(--online-color-text);
  color: var(--online-color-text);
}

.firstScreen.refresher.online .button--transparent:hover {
  color: var(--online-color-text);
}

.button--transparent:hover {
  color: var(--online-color-text);
  background: rgba(255, 255, 255, 0.3);
}

.button--transparent:active {
  background: rgba(0, 0, 0, 0.3);
}

.pd-bl-but2 {
  padding: 15px 20px 15px 20px;
}

.pd-bl-but {
  padding: 13px 14px 13px 20px;
}

.fsz16-lh20 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
}

button.button.bg-brand-itmo-bluetiful:hover {
  background: var(--design-bim-primary-black);
  border-color: var(--design-bim-primary-black);
}

a.button.bg-brand-itmo-bluetiful:hover {
  background: var(--design-bim-primary-black);
  border-color: var(--design-bim-primary-black);
}
