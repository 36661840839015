.redirect_block {
    position: relative;
    width: max-content;
    cursor: pointer;
}

.redirect_content {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    border-radius: 0px 0px 16px 16px;
    background: none;
    transition-duration: .1s;
}
.logo{
    display: block;
}
.redirect_block[data-open='open']>.redirect_content {
    background: #000;
    padding: 25px 20px 16px 20px;
    position: absolute;
    top: -40px;
    left: -20px;
}

.redir_list {
    transition-duration: .3s;
}

.redir_list>ul {
    display: none;
}

.redir_list[data-open='open'] {
    display: grid;
    gap: 24px;
}

.redir_list[data-open='open']>ul {
    display: grid;
    gap: 24px;
}

.redir_butt {
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding-top: 15px;
}
.redir_butt[data-open='open']>svg{
    transform: rotate(180deg);
}
.redir_butt[data-open='open']>svg>path{
    stroke:#fff;
}

.redir_help {
    position: absolute;
    top: 0;
    left: calc(100% + 16px);
    border-radius: 8px;
    background: #FFF;
    padding: 8px 12px;
    width: max-content;
    display: none;
}
.redirect_block[data-open='close']:hover>.redir_help{
    display: block;
}