.modal-textBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal__text {
  color: var(--design-bim-secondary-black);

  font-family: "ALS Gorizont", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.18px;
  padding-top: 2px;
}
