.rating {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 140%;
  font-family: "PT Golos", sans-serif;
  font-weight: 400;
  gap: 4px;
}

.firstScreen.refresher.online .rating,
.firstScreen.plain.online .rating {
  color: var(--online-color-text);
}

.firstScreen.refresher.offline .rating,
.firstScreen.plain.offline .rating {
  color: var(--offline-color-text);
}

#start-rating-icon path {
  fill: var(--highlight-color);
}
