.nav-status {
  height: 3px;
  background: var(--darken-main-color);
  width: 0%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  border-radius: 3px;
  filter: brightness(0.85);

  transition: width 0.2s ease;
}
