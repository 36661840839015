@font-face {
  font-family: "ALS Gorizont";
  src: url("../fonts/als-gorizont/ALSGorizont-ThinExpanded.woff") format("woff"),
  url("../fonts/als-gorizont/ALSGorizont-ThinExpanded.eot") format("embedded-opentype"),
  url("../fonts/als-gorizont/ALSGorizont-ThinExpanded.woff2") format("woff2"),
  url("../fonts/als-gorizont/ALSGorizont-ThinExpanded.ttf") format("truetype"),
  url("../fonts/als-gorizont/ALSGorizont-ThinExpanded.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "ALS Gorizont";
  src: url("../fonts/als-gorizont/ALSGorizont-LightExpanded.woff") format("woff"),
  url("../fonts/als-gorizont/ALSGorizont-LightExpanded.eot") format("embedded-opentype"),
  url("../fonts/als-gorizont/ALSGorizont-LightExpanded.woff2") format("woff2"),
  url("../fonts/als-gorizont/ALSGorizont-LightExpanded.ttf") format("truetype"),
  url("../fonts/als-gorizont/ALSGorizont-LightExpanded.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ALS Gorizont";
  src: url("../fonts/als-gorizont/ALSGorizont-RegularExpanded.woff") format("woff"),
  url("../fonts/als-gorizont/ALSGorizont-RegularExpanded.eot") format("embedded-opentype"),
  url("../fonts/als-gorizont/ALSGorizont-RegularExpanded.woff2") format("woff2"),
  url("../fonts/als-gorizont/ALSGorizont-RegularExpanded.ttf") format("truetype"),
  url("../fonts/als-gorizont/ALSGorizont-RegularExpanded.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ALS Gorizont";
  src: url("../fonts/als-gorizont/ALSGorizont-MediumExpanded.woff") format("woff"),
  url("../fonts/als-gorizont/ALSGorizont-MediumExpanded.eot") format("embedded-opentype"),
  url("../fonts/als-gorizont/ALSGorizont-MediumExpanded.woff2") format("woff2"),
  url("../fonts/als-gorizont/ALSGorizont-MediumExpanded.ttf") format("truetype"),
  url("../fonts/als-gorizont/ALSGorizont-MediumExpanded.otf") format("opentype");
  font-weight: 550;
  font-style: normal;
}

@font-face {
  font-family: "ALS Gorizont";
  src: url("../fonts/als-gorizont/ALSGorizont-BoldExpanded.woff") format("woff"),
  url("../fonts/als-gorizont/ALSGorizont-BoldExpanded.eot") format("embedded-opentype"),
  url("../fonts/als-gorizont/ALSGorizont-BoldExpanded.woff2") format("woff2"),
  url("../fonts/als-gorizont/ALSGorizont-BoldExpanded.ttf") format("truetype"),
  url("../fonts/als-gorizont/ALSGorizont-BoldExpanded.otf") format("opentype");
  font-weight: 750;
  font-style: normal;
}

@font-face {
  font-family: "ALS Gorizont";
  src: url("../fonts/als-gorizont/ALSGorizont-ExtraBoldExpanded.woff") format("woff"),
  url("../fonts/als-gorizont/ALSGorizont-ExtraBoldExpanded.eot") format("embedded-opentype"),
  url("../fonts/als-gorizont/ALSGorizont-ExtraBoldExpanded.woff2") format("woff2"),
  url("../fonts/als-gorizont/ALSGorizont-ExtraBoldExpanded.ttf") format("truetype"),
  url("../fonts/als-gorizont/ALSGorizont-ExtraBoldExpanded.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: "PT Golos";
  src: url("../fonts/pt-golos/Golos Text_Regular.woff") format("woff"),
  url("../fonts/pt-golos/Golos Text_Regular.eot") format("embedded-opentype"),
  url("../fonts/pt-golos/Golos Text_Regular.woff2") format("woff2"),
  url("../fonts/pt-golos/Golos Text_Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PT Golos";
  src: url("../fonts/pt-golos/Golos Text_Medium.woff") format("woff"),
  url("../fonts/pt-golos/Golos Text_Medium.eot") format("embedded-opentype"),
  url("../fonts/pt-golos/Golos Text_Medium.woff2") format("woff2"),
  url("../fonts/pt-golos/Golos Text_Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PT Golos";
  src: url("../fonts/pt-golos/Golos Text_DemiBold.woff") format("woff"),
  url("../fonts/pt-golos/Golos Text_DemiBold.eot") format("embedded-opentype"),
  url("../fonts/pt-golos/Golos Text_DemiBold.woff2") format("woff2"),
  url("../fonts/pt-golos/Golos Text_DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PT Golos";
  src: url("../fonts/pt-golos/Golos Text_Bold.woff") format("woff"),
  url("../fonts/pt-golos/Golos Text_Bold.eot") format("embedded-opentype"),
  url("../fonts/pt-golos/Golos Text_Bold.woff2") format("woff2"),
  url("../fonts/pt-golos/Golos Text_Bold.ttf") format("truetype");
  font-weight: 750;
  font-style: normal;
}

@font-face {
  font-family: "PT Golos";
  src: url("../fonts/pt-golos/Golos Text_Black.woff") format("woff"),
  url("../fonts/pt-golos/Golos Text_Black.eot") format("embedded-opentype"),
  url("../fonts/pt-golos/Golos Text_Black.woff2") format("woff2"),
  url("../fonts/pt-golos/Golos Text_Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
