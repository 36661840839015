.modalMain-wrapper {
  border-radius: 40px;
  border: 1px solid var(--design-bim-primary-black, #0d0d0d);
  background: var(--primary-text, #fff);
  padding: 48px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  height: calc(var(--vh) * 100);
  max-height: 800px;
  overflow: scroll;
}

.modalMain-wrapper::-webkit-scrollbar {
  display: none;
}

.modalMain .modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh) * 100);
  padding-left: 0;
  padding-right: 0;
}

.modalMain-wrapper--main {
  max-width: 721px;
  width: 100%;
}

.modalMain {
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  right: 0;
  z-index: 5;

  opacity: 0;
  display: none;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  overflow: scroll;
  overflow-x: hidden;
}

.modal-close {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
  flex-shrink: 0;
}

.modal-close:hover {
  background: var(--highlight-color);
  transition: background 0.2s ease-in;
}

.modalMain.modalMain--active {
  /*opacity: 1;*/
  /*pointer-events: all;*/
}

.modal__title {
  color: var(--design-bim-primary-black);
  font-family: "ALS Gorizont", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.32px;
  margin-top: 16px;
  padding-top: 2px;
}

.modalMain-wrapper--video {
  max-width: 1616px;
  width: 100%;
  background: transparent;
  padding: 0;
  padding-left: 80px;
  padding-right: 80px;
  border-color: transparent;
  height: 100%;
}

.modalMain-wrapper--doc {
  width: 100%;
  background: transparent;
  border-color: transparent;
  height: 100%;
  padding: 60px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 880px;
  max-width: 1240px;
}

.modalMain-wrapper--comment {
  max-width: 980px;
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
  height: 100%;
  max-height: 784px;
}

.modalMain-wrapper--courses {
  width: 100%;
}

.modalMain-wrapper--courses .modal-container {
  align-items: flex-start;
}

@media (max-width: 1200px) {
  .modalMain-wrapper--video {
    padding: 40px;
  }

  .modalMain-wrapper--doc {
    width: auto;
  }
}

@media (max-width: 768px) {
  .modalMain-wrapper {
    padding: 48px 20px;
  }

  .modalMain-wrapper--comment {
    padding: 0;
  }

  .modalMain-wrapper--doc {
    width: auto;
  }

  .modal__title {
    font-size: 28px;
    letter-spacing: initial;
    margin-top: 0px;
  }

  .modalMain-wrapper--comment {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .modalMain-wrapper {
    max-height: unset;
  }

  .modalMain-wrapper--video {
    padding: 0;
  }

  .modalMain-wrapper--comment {
    padding: 0;
  }

  .modalMain {
    height: 100%;
    width: 100%;
  }

  .modalMain-wrapper--comment {
    padding: 0;
  }

}

.constant-visible {
  opacity: 1 !important;
  display: unset;
  pointer-events: all;
}


