.program-frame-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  gap: 20px;
}

.program-frame-row.plain.offline {
  flex-direction: column;
  align-items: unset;
}

.program-frame-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.program-frame-wrapper {
  width: 100%;
  max-width: 1165px;
}

.program-frame-all {
  padding: 18px 24px 18px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--highlight-color);
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
  color: var(--highlight-color);
}

.program-frame-all:hover {
  background: var(--highlight-color);
  transition: background 0.2s ease-in;
  color: var(--main-offline-color);
  cursor: pointer;
}

.program-frame-all svg {
  flex-shrink: 0;
  transition: .5s;
}

.program-frame-all svg path {
  stroke: var(--highlight-color);
  transition: .5s;
}

.program-frame-all:hover svg path {
  stroke: var(--design-bim-primary-black);
  transition: .5s;
}

.program-frame-all--active svg {
  transform: rotate(-180deg)
}

.program-frame-all-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.programm-frame--acordBlock {
  display: flex;
  flex-direction: column;

}

@media (max-width: 1200px) {
  .program-frame-row {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
    align-items: start;
  }
}

@media (max-width: 576px) {
  .programm-frame--acordBlock {
    flex-direction: column-reverse;
  }

  .program-frame-all-wrapper {
    margin-top: 0;
  }
}
