.videoBlock-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 40px;
  height: 100%;
  padding-bottom: 40px;
}

.videoBlock {
  width: 100%;
  height: 100%;
}

@media (max-width: 576px) {
  .videoBlock {
    width: 320px;
    height: 240px;
  }
}
