.targets-frame-cover-content {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.targets-frame-cover-content span {

}

.targets-list-area {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 0fr);
  column-gap: 48px;
  row-gap: 24px;
  /*gap: 24px;*/
}

.targets-list-area.refresher.online {
  gap: 48px;
}

@media (max-width: 1200px) {
  .targets-list-area {
    display: flex;
    flex-direction: column;
  }

  .targets-list-area.plain.online,
  .targets-list-area.plain.offline,
  .targets-list-area.refresher.online {
    gap: 64px;
  }

  .targets-frame-cover-content {
    gap: 40px;
  }

  .points-list-item-text {
    margin-bottom: 32px;
  }

  .points-list-item:last-of-type .points-list-item-text {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .targets-list-area.plain.online,
  .targets-list-area.plain.offline,
  .targets-list-area.refresher.online {
    gap: 48px;
  }
}

@media (max-width: 576px) {
  .targets-frame-cover-content {
    gap: 32px;
  }

  .targets-list-area.refresher.online {
    gap: 32px;
  }

  .points-list-item-text {
    margin-bottom: 16px;
  }

  .targets-list-area {
    row-gap: 12px
  }
}
