.tag {
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  color: var(--design-bim-true-white);
  padding: 6px 16px;
  border-radius: 40px;
  background: var(--tag-color);
  font-family: "PT Golos", sans-serif;
  white-space: nowrap;
}
 
@media (max-width: 576px) {
  .tag {
    font-size: 14px;
  }
} 
