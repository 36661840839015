.info-name {
  color: var(--main-text-color);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 550;
  line-height: 115%; /* 59.8px */
  text-transform: uppercase;
  padding-top: 2px;
  hyphens: auto;
}

@media (max-width: 1200px) {
  .info-name {
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .info-name {
    font-size: 25px;
    font-weight: 750;
    line-height: 120%;
  }
}
