.title-withLine {
  padding-left: 32px;
  position: relative;
  color: var(--design-bim-primary-black);
}

.title-withLine::before {
  content: '';
  height: 100%;
  width: 8px;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--main-color);
  border-radius: 40px;
}

.title-description-area-title-row {
  display: flex;
  justify-content: space-between;
}

.title-description-area-title-row-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.title-area-text.text-design-bim-primary-black span {
  color: var(--main-color);
}

.title-area-text.text-design-bim-true-white span {
  color: var(--highlight-color);
}

.title-area-text {
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 750;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding-top: 2px;
}

.title-description-area {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title-description-area-text {
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}

@media (max-width: 1200px) {
  .title-area-text {
    font-size: 36px;
    letter-spacing: -0.36px;
  }
}

@media (max-width: 576px) {
  .title-area-text {
    font-size: 22px;
    letter-spacing: -0.26px;
  }

  .title-withLine {
    padding-left: 12px;
  }

  .title-withLine::before {
    width: 4px;
  }

  .title-description-area-title-row {
    flex-direction: column;
    gap: 16px;
  }

  .title-description-area-title-row-button {
    justify-content: flex-start;
  }

  .title-description-area-text {
    font-size: 16px;
  }

  .title-description-area {
    gap: 12px;
  }
}


