.contacts-frame {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.contacts-frame-item {
  position: relative;
  padding: 80px 40px;
  border-radius: 80px 80px 0 80px;
}

.contacts-frame-item.catalog,
.contacts-frame-item.plain.offline,
.contacts-frame-item.plain.online {
  border: 1px solid var(--design-bim-primary-black, #0D0D0D);
  background: var(--main-offline-color, linear-gradient(225deg, #08073D 0%, #04031D 50%, #08073D 100%));
}

.contacts-frame-item.refresher.online {
  background: var(--design-bim-true-white);
  border: 1px solid var(--offline-color);
}

.contacts-frame-item__title-wrapper {
  display: flex;
  max-width: 400px;
}

.contacts-frame-item__title {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.64px;
  margin-bottom: 8px;
  padding-top: 2px;
}

.contacts-frame-item__title.catalog,
.contacts-frame-item__title.plain.offline,
.contacts-frame-item__title.plain.online {
  color: var(--design-bim-true-white);
}

.contacts-frame-item__title.refresher.online {
  color: var(--design-bim-primary-black);
}

.contacts-frame-item__text.catalog,
.contacts-frame-item__text.plain.offline,
.contacts-frame-item__text.plain.online {
  color: var(--design-bim-true-white);
}

.contacts-frame-item__text.refresher.online {
  color: var(--design-bim-primary-black);
}

.contacts-frame-item__title.catalog span,
.contacts-frame-item__title.plain.offline span,
.contacts-frame-item__title.plain.online span {
  color: var(--highlight-color);
}

.contacts-frame-item__title.refresher.online span {
  color: var(--main-color);
}

.contacts-frame-item-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 574px;
}

.contacts-frame-item__text {
  margin-bottom: 40px;
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: 140%
}

.contacts-frame-item-img {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.contacts-frame-item-img svg * circle {
  fill: var(--tag-color);
}

.contacts-frame-item-img.refresher {
  display: none;
}

@media (max-width: 1200px) {
  .contacts-frame-item__title-wrapper {
    max-width: unset;
  }

  .contacts-frame {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  .contacts-frame-item {
    border-radius: 64px 64px 0px 64px;
    padding: 64px 40px;
  }

  .contacts-frame-item__text {
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .contacts-frame-item-wrapper {
    max-width: unset;
  }

  .contacts-frame-item__title {
    font-size: 36px;
    letter-spacing: -0.36px;
    font-weight: 750;
    line-height: 140%;
  }
}

@media (max-width: 768px) {
  .contacts-frame-item-img {
    display: none;
  }

  .contacts-frame-item .button {
    width: 100%;
  }

  .contacts-frame-item {
    border-radius: 40px 40px 40px 40px;
  }
}

@media (max-width: 576px) {
  .contacts-frame {
    row-gap: 20px;
  }

  .contacts-frame-item {
    border-radius: 40px 40px 40px 40px;
    padding: 48px 20px;
  }

  .contacts-frame-item__text {
    font-size: 16px;
    letter-spacing: initial;
    margin-bottom: 32px;
  }

  .contacts-frame-item__title {
    font-size: 22px;
    line-height: 120%; /* 31.2px */
    letter-spacing: -0.22px;
  }
}
