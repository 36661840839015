@import 'reset-css/reset.css';

* {
  box-sizing: border-box;
}

:root {
  --online-color-text: '';
  --offline-color: '';
  --offline-color-text: '';
  --light-color-high: '';
  --design-bim-secondary-black: #424245;
  --design-bim-true-white: #ffffff;
  --design-bim-primary-black: #0D0D0D;
  --gray-blue-25-bg-hover: #F6F7F8;
  --brand-itmo-white: #FFF;
}

.color--online-color-text g path,
.color--online-color-text {
  color: var(--online-color-text);
  fill: var(--online-color-text);
}

.color--online-color-text:hover g path,
.color--online-color-text:hover {
  color: var(--offline-color-text);
  fill: var(--offline-color-text);
}

.color--offline-color g path {
  color: var(--offline-color);
  fill: var(--offline-color);
}

.border--offline-color {
  border: 1px solid var(--offline-color);
}

.border-bottom--offline-color {
  border-bottom: 1px solid var(--offline-color);
}

.border--online-color-text {
  border: 1px solid var(--online-color-text);
}

.bg-gradient-black {
  background: linear-gradient(257.81deg, #08073D -13.08%, #04031D 50.69%, #08073D 114.46%);
}

.bg-gradient-darkblue {
  background: linear-gradient(225deg, #08073D 0%, #04031D 50%, #08073D 100%);
}

.bg-design-bim-gradient-black {
  background: var(--main-offline-color);
}

.bg-green-lizard {
  background: linear-gradient(0deg, var(--highlight-color), var(--highlight-color)), linear-gradient(0deg, #0D0D0D, #0D0D0D);
}

.bg-brand-itmo-bluetiful {
  background: var(--main-color);
}

.bg-design-bim-gradient-blue {
  background: var(--background-gradient-color);
}

.bg-design-bim-true-white {
  background: var(--design-bim-true-white);
}

.bg-design-bim-primary-black {
  background: var(--design-bim-primary-black);
}

.text-design-bim-true-white {
  color: var(--design-bim-true-white);
}

.text-design-bim-primary-black {
  color: var(--design-bim-primary-black);
}

.text-brand-itmo-bluetiful {
  color: var(--main-color);
}

.text-brand-itmo-green-lizard {
  color: var(--highlight-color);
}

.no-shrink {
  flex-shrink: 0;
}

body {
  min-width: 360px;
  overflow-x: hidden;
}

.no-padding {
  padding: 0;
}

.no-border {
  border: 0;
}

.no-wrap {
  white-space: nowrap;
}

.border--design-bim-primary-black {
  border: 1px solid var(--design-bim-primary-black);
}

.border--design-bim-primary-black--top-bottom {
  border-top: 1px solid var(--design-bim-primary-black);
  border-bottom: 1px solid var(--design-bim-primary-black);
}

/* Работает в Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--main-color);
}

/* Работает в Chrome, Edge и Safari */
*::-webkit-scrollbar {
  width: 10px;
}

.modalMain::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: rgba(197, 204, 217, 0.9);
}

*::-webkit-scrollbar-thumb {
  background-color: #999ea1;
  border-radius: 40px;
}

.router-link {
  color: inherit;
  text-decoration: inherit;
}

html {
  background-color: var(--main-color);
}
