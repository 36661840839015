.filter-area {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.filter-format-area {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-category-divider {
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 1;
}

.filter-category-area {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.filter-item {
  display: flex;
  padding: 16px 16px 14px 16px;
  border-radius: 40px;
  border: 1px solid var(--design-bim-primary-black, #0D0D0D);

  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.16px;
  text-transform: uppercase;
  user-select: none;
}

.filter-item:hover {
  cursor: pointer;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.filter-item.selected {
  color: var(--main-text-color, #FFF);

  background: var(--main-color);
  border: 1px solid var(--offline-color);

  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.filter-item-gap {
  display: flex;
  font-size: 30px;
  margin: 0 8px;
}

.filter-item-gap-wrapper {
  width: 1px;
  height: 30px;
  content: '';
  background: #000000;
}

@media (max-width: 576px) {
  .filter-category-divider {
    display: unset;
  }

  .filter-area {
    display: none;
  }

  #filter-modal-frame-id .filter-area {
    display: flex;
  }

  .filter-item-gap {
    display: none;
  }
}
