.rating-comment {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.rating-comment-row {
  display: flex;
  align-items: center;
}

.rating-comment__text {
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;

  color: var(--design-bim-primary-black);
}

@media (max-width: 576px) {
  .rating-comment__text {
    font-size: 16px;
  }
}
