.filter-button {
  display: none;
  opacity: 0;
  position: fixed;
  bottom: 80px;
  right: 20px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  pointer-events: none;
  background: white;
  border-radius: 32px;
  padding: 2px;
  min-width: 48px;
  height: 48px;
  border: 2px solid black;
}

.filter-button:hover {
  border: 2px solid var(--main-color);
}

.filter-button:hover svg * {
  stroke: var(--main-color);
}

.filter-button-text {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--design-bim-primary-black, #0D0D0D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.16px;
  margin-right: 12px;
  padding-top: 2px;
}

.filter-button-text span {
  color: var(--main-color);
  font-weight: 750;
}

.filter-button-image {
  flex-shrink: 0;
  margin-left: 12px;
}

.filter-button.catalog {
  bottom: 23px;
}

.filter-button.shown {
  pointer-events: all;
  opacity: 1;
}

.filter-button:hover {
  cursor: pointer;
}

.scroll-up-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--highlight-color);
  width: 48px;
  height: 48px;
  border: 2px solid black;
}

.scroll-up-button:hover {
  background: white;
  transition: background 0.3s ease-in-out;
}

.filter-button.move {
  transform: translate(0px, 60px);
}

@media (max-width: 576px) {
  .filter-button {
    display: flex;
    bottom: 60px;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .filter-button.move {
    transform: translate(0px, 40px);
  }
}
