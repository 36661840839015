.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 32px;
}

.nav-list-item, .nav-list-item--link {
  display: flex;
  align-items: center;
  height: 100%;
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  text-decoration: none;
  line-height: 125%;
  letter-spacing: -0.14px;
  white-space: nowrap;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}

.nav-list-item.catalog,
.nav-list-item.refresher {
  color: var(--design-bim-true-white);
}

.nav-list-item--link:before {
  content: '';
  opacity: 0;
}

.nav-list-item--link:hover::before {
  content: '';
  width: 100%;
  height: 3px;
  top: 75px;
  position: absolute;
  left: 0;
  border-radius: 3px;
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.nav-list-item--link.plain.online:hover::before,
.nav-list-item--link.plain.offline:hover::before {
  background: var(--main-color);
}

.nav-list-item--link.catalog:hover::before,
.nav-list-item--link.refresher.online:hover::before {
  background: var(--design-bim-true-white);
}

.nav-list-item--link.plain.online:hover,
.nav-list-item--link.plain.offline:hover {
  color: var(--main-color);
  transition: color 0.1s ease-in;
}

.nav-list-item--link.refresher.online:hover {
  color: var(--design-bim-true-white);
  transition: color 0.1s ease-in;
}

@media (max-width: 1600px) {
  .nav-list {
    gap: 16px;
  }
}

@media (max-width: 1400px) {
  .nav-list {
    flex-direction: column;
    gap: 1px;
  }

  .nav-list-item {
    width: 100%;
  }

  .nav-list-item--link {
    padding: 24px 40px;
    width: 100%;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.2px
  }

  .nav-list-item--link:hover::before {
    display: none;
  }

  .nav-list-item--link.catalog,
  .nav-list-item--link.refresher.online,
  .nav-list-item--link.refresher.online:hover {
    color: var(--design-bim-primary-black);
  }

  .nav-list-item:hover, .nav-list-item:focus {
    background: #F6F7F8;
  }

  .nav-list-item:hover .nav-list-item--link, .nav-list-item:focus .nav-list-item--link {
    color: var(--main-color);
  }
}

@media (max-width: 576px) {
  .nav-list-item--link {
    padding: 20px;
    font-size: 18px;
    letter-spacing: normal;
  }

  .nav-list {
    gap: 0;
  }
}
