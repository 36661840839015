.courses-frame,
.dynamic-courses-enumeration-area {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.courses-frame-title {
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 750;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding-top: 2px;
}

.courses-frame-address-icon circle,
.courses-frame-address-icon path {
  stroke: var(--main-color);
}

.courses-frame-address-icon #inner-circle {
  stroke: var(--main-color);
  fill: var(--main-color);
}

.courses-frame-title span {
  color: var(--main-color);
}

.courses-frame-address-area {
  display: flex;
  margin-top: -40px;
}

.courses-frame-address-area-wrapper {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 12px;
  background: var(--light-color-high);
  cursor: pointer;
}

.courses-frame-address-area-wrapper:hover {
  filter: brightness(0.95);
  transition: filter 0.2s ease-in;
}

.courses-frame-address-text {
  display: flex;
  align-items: center;
  color: var(--offline-color);

  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  letter-spacing: 0.14px;
  gap: 5px;
}

.courses-frame-address-text span {
  color: var(--main-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@media (max-width: 1000px) {
  .courses-frame-address-text {
    flex-direction: column;
    align-items: start;
  }

  .courses-frame-address-icon {
    display: none;
  }

  .courses-frame-title {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .courses-frame {
    gap: 48px;
  }

  .courses-frame-address-area {
    margin-top: 0px;
  }

  .courses-frame-title {
    font-size: 24px;
  }

  .dynamic-courses-enumeration-area {
    gap: 40px;
  }
}
