.personalized-price {
  position: relative;
  width: 100%;
  padding: 0 0 24px 0;
}

.personalized-price::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 100%;
  bottom: 0;
  background: #C9D4EB66;
}

.personalized-price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 36px;
}

.pp-person-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 600px;
}

.pp-person {
  display: flex;
  gap: 8px;
}

.pp-price-area {
  display: flex;
  gap: 12px;
  align-items: center;
  white-space: nowrap;
}

.pp-price {
  display: flex;
  gap: 8px;
}

.pp-price-discount {
  display: flex;
  position: relative;

  color: rgba(255, 255, 255, 0.60);
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;

  font-family: 'ALS Gorizont', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  padding-top: 2px;
}

.pp-price-discount::after {
  background-color: #FFF;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 45%;
  left: 0;
  transform: rotate(-12deg);
  width: 100%;
}

.pp-person-text, .pp-price {
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.56px;
  padding-top: 2px;
}

.pp-price #currency,
.pp-price-text span,
.pp-person-text span {
  color: var(--highlight-color);
}

.pp-price-discount-description {
  color: var(--design-bim-true-white);
  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  opacity: 0.6;
}

.pp-price-discount-description2 {
  display: none;
}

@media (max-width: 768px) {
  .pp-price-area {
    flex-direction: column;
    align-items: flex-end;
  }

  .pp-person-area {
    max-width: 300px;
  }

  .pp-person-text, .pp-price {
    font-size: 22px;
  }

  .pp-price-discount-description {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .personalized-price-wrapper {
    gap: 8px;
  }

  .pp-person-area {
    max-width: 200px;
  }

  .pp-person-text {
    font-size: 18px;
  }

  .pp-price-discount-description {
    font-size: 14px;
  }

  .pp-price-discount {
    font-size: 12px;
    line-height: 140%;
  }

  .pp-price-area {
    gap: 0;
  }

  .personalized-price {
    padding-bottom: 16px;
  }

  .pf-prices {
    gap: 16px;
  }

  .pp-price-discount-description {
    display: none;
  }

  .pp-price-discount-description2 {
    display: flex;
    margin-top: 12px;
  }

  .personalized-price.price-list-noborder {
    padding-bottom: 24px;
  }

  .personalized-price.price-list-noborder::after {
    display: none;
  }
}
