.swiping-works {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.swiping-works-title {
  color: var(--brand-itmo-white, #FFF);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 550;
  line-height: 130%;
  padding-top: 2px;
}

.swiping-works-title span {
  color: var(--highlight-color);
}

.swiping-works-swiper {
  width: 50vw;
}

.swiping-works-slide {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.swiping-works-slide-img-area {
  border-radius: 40px;
  overflow: hidden;
  border: 1px solid var(--design-bim-primary-black, #0D0D0D);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.swiping-works-slide-img-area:hover {
  cursor: pointer;
}

.swiping-works-slide-img-area::before {
  content: '';
}

.swiping-works-slide-img-area:hover::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  transition: background 0.2s ease-in-out;
}

.swiping-works-slide-img-area:hover::after {
  content: '';
  width: 40px;
  height: 40px;
  background: url('/public/imgStatic/icon.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.swiping-works-slide-img {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.swiping-works-slide-author {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding-top: 2px;
  line-height: 130%;
}

.swiping-works-swiper .swiper-slide {
  width: calc((100% - 16px) / 2);
}

@media (min-width: 2000px) {
  .swiping-works-swiper .swiper-slide {
    width: calc((100% - 32px) / 3);
  }
}

@media (max-width: 1200px) {
  .swiping-works-swiper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .swiping-works-swiper .swiper-slide {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .swiping-works-title {
    font-size: 18px;
    font-weight: 550;
    line-height: 130%;
    letter-spacing: -0.36px;
    text-transform: uppercase;
  }

  .swiping-works {
    gap: 10px;
  }
}
