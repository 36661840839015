.taxes-frame {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 64px;
}

.frame-cover.taxes-frame {
  border: 1px solid var(--offline-color);
}

.taxes-frame-left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1000px;
}

.taxes-description-title {
  color: var(--online-color-text);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 750;
  line-height: 120%;
  letter-spacing: -0.4px;
  padding-top: 2px;
}

.taxes-description-description {
  color: var(--online-color-text);
  font-family: 'PT Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 700px;
}

.taxes-description-description span {
  color: var(--highlight-color);
  cursor: pointer;
}

.taxes-frame-right {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.taxes-frame-right svg path {
  fill: var(--second-highlight-color);
}

.taxes-frame-tax-value {
  position: absolute;
  font-family: 'ALS Gorizont', sans-serif;;
  font-size: 55px;
  font-weight: 750;
  color: var(--second-highlight-color);
  transform: rotate(-15deg);
  padding-top: 2px;
}

@media (max-width: 1200px) {
  .taxes-description-title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .taxes-frame-right {
    display: none;
  }
}

@media (max-width: 576px) {
  .taxes-description-title {
    font-size: 22px;
  }

  .taxes-description-description {
    font-size: 16px;
  }
}
