.success-status-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 25px;
  min-height: calc(var(--vh) * 100 - 180px);
  align-items: center;
}

.success-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.success-status-text-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.success-status-text {
  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding-top: 2px;
}

.success-status-text.secondary {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'PT Golos', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
}
