.courses-enumeration-area {
  display: flex;
  flex-direction: column;
  gap: 48px;
  opacity: 0;
}

.ce-separation-area {
  display: flex;
  gap: 16px;
}

.ce-separation-area-text {
  color: var(--design-bim-primary-black, #0D0D0D);
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 550;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.32px;
  text-transform: uppercase;
  padding-top: 2px;
}

.ce-separation-area-text span {
  color: var(--main-color);
}

.ce-separation-arrow {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.ce-separation-arrow-body {
  width: 100%;
  height: 1px;
  background: black;
}

.ce-separation-arrow-arrow {
  margin-left: -1px;
}

.ce-courses-area {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 48px;
  column-gap: 16px;
}

@media (max-width: 1500px) {
  .ce-separation-arrow {
    display: none;
  }
}

@media (max-width: 1200px) {
  .ce-courses-area {
    grid-template-columns: 1fr;
    row-gap: 35px;
  }
}

@media (max-width: 576px) {
  .ce-separation-arrow {
    display: none;
  }

  .ce-separation-area-text {
    font-size: 22px;
  }

  .courses-enumeration-area {
    gap: 24px;
  }
}
