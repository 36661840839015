.education-stages-frame-content {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.education-stages-frame-content .swiper-buttonCont-pagination {
  color: var(--offline-color-text);
}

.es-stage b {
  font-weight: 600;
}

.es-info-description a {
  color: var(--main-color);
  text-decoration-line: underline;
}

.es-tabs-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.es-tabs {
  display: flex;
  gap: 16px;
  border-bottom: 1px solid var(--highlight-color);
}

.es-tab {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;

  color: var(--highlight-color);

  border-radius: 8px 24px 0px 0px;
  border: 1px solid var(--highlight-color);
  border-bottom: 0;
}

.es-tab:hover {
  cursor: pointer;
}

.es-tab.selected,
.es-tab:hover {
  color: var(--design-bim-primary-black);
  background: var(--highlight-color);
  transition: background 0.2s ease-in, color 0.2s ease-in;
}

.es-tab-text {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.18px;
  padding-top: 2px;
}

.es-stages-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.es-stage {
  display: flex;
  justify-content: space-between;
  border-radius: 0px 40px 40px 40px;
  background: var(--design-bim-true-white, #fff);
  gap: 28px;
  overflow: hidden;
}

.es-stage-leftSide {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 700px;
  padding: 72px 32px 72px 32px;
}

.es-info-area {
  display: flex;
  gap: 16px;
}

.es-info-index {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  color: var(--main-color);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: "ALS Gorizont", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 100;
  line-height: 130%;
  letter-spacing: -0.56px;
  padding: 2px 6.286px 0px 4px;
}

.es-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.es-info-title {
  color: var(--design-bim-primary-black);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: "ALS Gorizont", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.56px;
  padding-top: 2px;
  line-height: 130%;
}

.es-info-description {
  color: var(--design-bim-secondary-black);
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}

.es-info-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.es-stage-rightSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 600px;
  padding: 72px 32px 0 32px;
}

.es-stage-name {
  display: flex;
  gap: 8px;
}

.swiper-WidthFull {
  width: 100%;
}

.swiper-slide {
  height: auto;
}

.swiper-buttonCont {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 28px;
}

.swiper-buttonCont-button {
  cursor: pointer;
}

.swiper-buttonCont-button:hover circle {
  fill: var(--highlight-color);
  transition: fill 0.2s ease-in;
}

.swiper-buttonCont-button.swiper-button-disabled svg {
  opacity: 0.5;
}

.swiper-buttonCont-pagination {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  line-height: 140%;
  color: var(--online-color-text);
  letter-spacing: -0.18px;
  font-family: "PT Golos", sans-serif;
  width: auto;
}

.swiper-buttonCont-pagination span {
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.18px;
  font-family: "PT Golos", sans-serif;
}

@media (max-width: 1600px) {


  .es-stage-leftSide {
    padding: 72px 0px 72px 32px;
  }

  .es-stage-rightSide {
    padding: 60px 30px 0px 0px;
  }
}

@media (max-width: 1400px) {
  .es-stage {
    flex-direction: column;
  }

  .es-stage-leftSide {
    width: 100%;
    padding-bottom: 0;
    padding-right: 32px;
    max-width: unset;
  }

  .es-stage-rightSide {
    align-self: center;
    padding-top: 30px;
    padding-right: 0;
  }
}

@media (max-width: 1200px) {
  .es-stage {
    flex-direction: column;
    gap: 0;
  }

  .es-info-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .es-stage-leftSide {
    max-width: unset;
    padding: 72px 32px 0px 32px;
  }

  .es-stage-rightSide {
    align-self: initial;
    max-width: unset;
    padding: 60px 60px 0px 60px;
  }
}

@media (max-width: 768px) {
  .education-stages-frame-content {
    gap: 48px;
  }
}

@media (max-width: 576px) {
  .education-stages-frame-content {
    gap: 32px;
  }

  .es-info-area {
    flex-direction: column;
    gap: 6px;
  }

  .es-stage-leftSide {
    padding: 24px 20px 0px 20px;
  }

  .es-info-buttons {
    flex-direction: column;
  }

  .es-stage-rightSide {
    padding: 40px 0 0;
  }

  .es-stage {
    height: 100%;
    border-radius: 0px 24px 24px 24px;
  }

  .es-tabs-area .swiper-buttonCont {
    margin-top: 16px;
  }

  .es-tab-text {
    font-size: 14px;
    letter-spacing: initial;
  }

  .es-tabs-area {
    gap: 16px;
  }

  .es-tabs {
    gap: 8px;
  }

  .es-tab {
    padding: 8px 12px;
    border-radius: 8px 16px 0px 0px;
  }

  .es-info {
    gap: 8px;
  }

  .es-info-buttons {
    margin-top: 16px;
  }

  .es-info-buttons .button {
    width: 100%;
  }

  .es-info-title {
    font-size: 20px;
    letter-spacing: initial;
    line-height: 130%;
  }

  .es-info-index {
    font-size: 40px;
  }

  .es-info-description {
    font-size: 16px;
  }

  .swiper-buttonCont {
    margin-top: 16px;
  }

  .es-info-buttons > div {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .es-tab-text {
    font-size: 15px;
  }
}

@media (max-width: 410px) {
  .es-tab {
    padding: 8px 12px;
  }

  .es-tab-text {
    font-size: 14px;
  }
}
