.education-process-frame-content {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.education-process-frame-content .title-area-text {
  color: var(--online-color-text);
}

.ep-content-slider-area {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

#education-process-frame .frame-cover {
  padding-bottom: 40px;
}

#education-process-frame.frame {
  padding-top: 0;
}

.educ-process-slider {
  display: flex;
  border-radius: 40px;
  border: 1px solid var(--design-bim-primary-black);
  background: var(--design-bim-true-white);
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
}

.educ-process-slider-column {
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--design-bim-primary-black);
  max-width: 768px;
}

.educ-process-slider.offline .educ-process-slider-column,
.educ-process-slider.online .educ-process-slider-column {
  max-width: 868px;
  padding-bottom: 108px;
}

.educ-process-slider-column-img {
  width: 600px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.educ-process-slider.offline .educ-process-slider-column-img,
.educ-process-slider.online .educ-process-slider-column-img {
  width: 500px;
}

.educ-process-slider__title {
  font-size: 28px;
  font-weight: 400;
  line-height: 110%; /* 30.8px */
  letter-spacing: -0.56px;
  font-family: "ALS Gorizont", sans-serif;
  padding-top: 2px;
}

.educ-process-slider__text {
  font-family: "PT Golos";
  font-size: 20px;
  font-weight: 400;
  line-height: 140%; /* 28px */
  letter-spacing: -0.2px;
  color: var(--design-bim-secondary-black);
}

.educ-process-slider__text span {
  color: var(--main-color);
  cursor: pointer;
}

.educ-process-slider__text a.more-info-button {
  margin-left: 8px;
}

.educ-process-slider__text a.more-info-button:hover {
  cursor: pointer;
}

.ed-proccess-imgPlay {
  position: relative;
}

.ed-procces-imgPlay-svg {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ed-procces-imgPlay-svg-playSvg {
  position: absolute;
  width: 54px;
  height: 54px;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ed-procces-imgPlay-svg-playSvg path {
  fill: var(--main-color);
}

.ed-procces-imgPlay-svg-svgRotate {
  animation: 20s linear 0s normal none infinite running rot;
  -webkit-animation: 20s linear 0s normal none infinite running rot;
}

.ed-procces-imgPlay-svg-svgRotate * path {
  fill: var(--main-color);
}

.ed-procces-imgPlay-svg:hover svg path {
  fill: var(--lighten-main-color);
}

.educ-process-slider__text b {
  font-weight: 600;
}

.educ-process-slider-addInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #C9D4EB;
}

.educ-process-slider-addInfo__text {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: var(--design-bim-tertiary-black, #656D7C);
  font-family: "PT Golos";
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.14px;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ed-proccess-imgLink {
  position: relative;
}

.ed-proccess-imgLink__link {
  color: var(--main-color);
  text-align: center;
  font-family: "ALS Gorizont";
  font-size: 17px;
  font-weight: 500;
  line-height: 120%; /* 20.4px */
  letter-spacing: -0.17px;
  text-decoration: none;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ed-proccess-imgLink__link:hover {
  cursor: pointer;
}


@media (max-width: 1200px) {
  .education-process-frame-content {
    gap: 40px;
  }

  .educ-process-slider {
    flex-direction: column;
    justify-content: space-between;
  }

  .educ-process-slider-column {
    padding: 72px 48px 0 48px;
    max-width: unset;
  }

  .educ-process-slider-column-img {
    padding-top: 20px;
    padding-bottom: 80px;
    width: 100%;
    max-width: 880px;
  }

  .educ-process-slider-column-img--content {
    max-height: 250px;
  }

  .educ-process-slider-column-img img {
    width: 100%;
    max-width: 300px;
  }

  .educ-process-slider.offline .educ-process-slider-column,
  .educ-process-slider.online .educ-process-slider-column {
    max-width: unset;
    padding-bottom: 0;
  }

  .educ-process-slider.offline .educ-process-slider-column-img,
  .educ-process-slider.online .educ-process-slider-column-img {
    padding-top: 20px;
    max-width: 500px;
    width: 100%;
  }

  .educ-process-slider.offline .educ-process-slider-column-img.educ-process-slider-column-img0,
  .educ-process-slider.online .educ-process-slider-column-img.educ-process-slider-column-img0 {
    width: 800px;
  }

  .educ-process-slider.offline .educ-process-slider-column-img.educ-process-slider-column-img1 svg,
  .educ-process-slider.online .educ-process-slider-column-img.educ-process-slider-column-img1 svg {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .educ-process-slider-column-img {
    padding-top: 20px;
    padding-bottom: 50px;
    width: 100%;
    max-width: 880px;
  }

  .educ-process-slider-column-img--content {
    max-height: 200px;
  }

  .education-process-frame-content {
    gap: 24px;
  }

  .educ-process-slider-column {
    padding: 48px 20px 0 20px;
    gap: 8px;
  }

  .educ-process-slider__title {
    font-size: 20px;
    line-height: 130%;
    letter-spacing: initial;
  }

  .educ-process-slider__text {
    font-size: 16px;
    letter-spacing: initial;
  }

  /* } */
  .ed-procces-imgPlay-svg-svgRotate {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 520px) {
  .educ-process-slider-column-img {
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
  }
  
  .educ-process-slider-column-img--content {
    max-height: 140px;
  }

  .ed-procces-imgPlay-svg-playSvg {
    width: 30px;
    height: 30px;
    top: 50%;
  }

  .ed-procces-imgPlay-svg {
    width: 70px;
    height: 70px;
  }

  .ed-proccess-imgLink {
    height: 100%;
  } 
}
