form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 604px;
}

form > textarea,
form > select,
form > input {
  display: flex;
  padding: 16px;

  border-radius: 8px;
  border: 2px solid rgba(13, 13, 13, 0.12);

  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

form > select {
  cursor: pointer;

  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

form > textarea:focus,
form > select:focus,
form > input:focus {
  outline: none;
  border: 2px solid var(--main-color);
  transition: border-color 0.2s ease-in;
}

form > select.error,
form > textarea.error,
form > input.error {
  border-color: #d50000;
  color: #d50000;
  transition: border-color 0.2s ease-in;
}

form > span.error-text {
  font-size: 14px;
  font-family: 'PT Golos', sans-serif;
  color: #d50000;
  margin-bottom: 5px;
}

form > select.error,
textarea.error::placeholder,
input.error::placeholder {
  color: rgba(213, 0, 0, 0.5);
}

textarea::placeholder,
input::placeholder {
  color: rgba(66, 66, 69, 0.50);
}

form > .title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: 'ALS Gorizont', sans-serif;
  padding-top: 2px;
}

.error-text {
  color: var(--danger-default-700, #E02D3C);
  font-family: "PT Golos", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.13px;
}

input.text-message {
}

textarea {
  resize: none;
}

.checkboxes-area {
  display: flex;
  gap: 20px;
}

.checkbox-area {
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'PT Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

input[type="checkbox"] {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: var(--main-color);
}

input[type="checkbox"]:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.default {
  color: rgba(66, 66, 69, 0.50);
}
