.work-frame .title-area-text {
  color: var(--online-color-text);
}

.work-frame-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.work-community {
  margin-top: 80px;
  padding: 16px 32px 0 40px;
  border-radius: 40px 40px 0 0;
  border: 1px solid var(--design-bim-primary-black);
  background: var(--design-bim-true-white);
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: none;
}

.work-community.marginTop-0 {
  margin-top: 0;
}

.work-community-header {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 24px;
  align-items: center;
}

.work-community-header-tag {
  color: var(--design-bim-primary-black);
  font-family: "ALS Gorizont", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.24px;
  border-radius: 40px;
  border: 1px solid var(--design-bim-primary-black);
  padding: 6px 12px 4px 12px;
}

.work-community-header-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.work-community-header-line {
  height: 1px;
  border-radius: 8px;
  background: var(--design-bim-primary-black);

}

.work-community-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.work-community-body-column {
  padding-bottom: 40px;
}

.work-community__title {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 32px;
  font-weight: 750;
  letter-spacing: -0.32px;
  margin-bottom: 16px;
  padding-top: 2px;
  line-height: 130%;
}

.work-community-textBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.work-community__text {
  font-family: "PT Golos", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.18px;
  color: var(--design-bim-secondary-black);
  position: relative;
}

.work-community__tooltip {
  background: none !important;
}

.work-community__text-tooltip {
  padding: 30px 20px;
  margin-top: -5px;
  gap: 8px;
  border-radius: 24px;
  background: var(--design-bim-primary-black, #0D0D0D);

  color: var(--brand-itmo-white);
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.work-community__text-tooltip span {
  font-weight: 600;
}

.work-community__text span {
  font-weight: 600;
}

.work-community__text .mentor {
  color: var(--main-color);
  text-decoration: underline dashed;
  text-underline-offset: 5px;
}

.work-community-body-column-b {
  width: 100%;
  max-width: 652px;
  position: relative;
}

.work-community-body-column2 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.work-community-body-column2 img {
  object-fit: cover;
  width: 75%;
}

.work-community__title span {
  color: var(--main-color);
}

.swiper-work .swiper-slide {
  width: 456px;
}

.work-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  height: 100%;
  cursor: pointer;
}

.work-slide-imgBlock {
  border-radius: 40px;
  overflow: hidden;
  border: 1px solid var(--design-bim-primary-black, #0D0D0D);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.work-slide-imgBlock::before {
  content: '';
}

.work-frame .work-slide:hover .work-slide-imgBlock::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  transition: background 0.2s ease-in-out;
}

.work-frame .work-slide .work-slide-imgBlock::after {
  content: '';
  opacity: 0;
  width: 40px;
  height: 40px;
  background: url('/public/imgStatic/icon.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.work-frame .work-slide:hover .work-slide-imgBlock::after {
  content: '';
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.video-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 10px;
  background: var(--highlight-color);
  border-radius: 12px;
  color: var(--offline-color);
  font-family: 'PT Golos', sans-serif;
  border: 1px solid var(--offline-color);
}

.video-tag-play {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 42px;
  height: 42px;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
}

.work-slider__img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.work-slider__img2 {
  width: unset;
}

.work-slide__author {
  font-family: "ALS Gorizont", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding-top: 2px;
  line-height: 130%;
}

.swiper-buttonCont--grid {
  display: grid;
  grid-template-columns: 56px min-content 56px 1fr;
}

.work-community-linePos {
  height: 1px;
  background: #0D0D0D;
}

.work-frame-row-item {
  font-family: "PT Golos", sans-serif;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #fff;
}

.work-community-body-column2__img2 {
  cursor: pointer;
  max-height: 250px;
  display: none;
}

.work-community-body-column2__img.video {
  cursor: pointer;
  object-fit: contain;
}

.tooltip-block-toOPen {
  display: inline;
  margin-left: 5px;
}

.tooltip-block-toOPen:hover {
  cursor: default;
}

.tooltip {
  position: absolute;
  width: 320px;
  /* bottom: 0;
  bottom: 30px;
  left: 0; */
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100%);
}

.tooltip--active {
  opacity: 1;
  pointer-events: all;
}

.tooltip-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #FFF;
  font-family: "PT Golos";
  font-size: 13px;
  font-weight: 400;
  line-height: 125%;
  /* 16.25px */
  letter-spacing: 0.13px;
  padding: 8px 12px;
  border-radius: 8px;
  background: var(--design-bim-primary-black);
  position: relative;
}

.tooltip-rectangle {

  position: absolute;
  left: 20px;
  right: unset;
  bottom: -19px;
  border: 10px solid transparent;
  border-top: 10px solid var(--design-bim-primary-black);
}

/* .tooltip--right .tooltip-rectangle{
  left: unset;
  right: 20px;
} */
.tooltip span {
  font-weight: 600;
}

.work-community-body-column2__img.two {
  display: flex;
  object-fit: cover;
  object-position: top;
  max-width: 600px;
  max-height: 350px;
}

@media (max-width: 1600px) {
  .work-community {
    margin-left: 100px;
    margin-right: 100px;
  }

  .frame-cover.work-frame {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-work,
  .work-frame-row {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 1400px) {
  .work-community {
    margin-left: 70px;
    margin-right: 70px;
  }

  .swiper-work,
  .work-frame-row {
    padding-left: 70px;
    padding-right: 70px;
  }

  .work-community-body-column2__img.two {
    max-width: 600px;
    max-height: 300px;
  }
}


@media (max-width: 1200px) {

  .swiper-work,
  .work-frame-row {
    padding-left: 40px;
    padding-right: 40px;
  }

  .work-frame-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 40px;
  }

  .work-community {
    margin-top: 68px;
    gap: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .work-community-body {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .work-community-body-column-b {
    max-width: unset;
  }

  .work-community-body-column2 img {
    max-height: 300px;
    min-height: 250px;
    width: 90%;
  }

  .work-community-body-column {
    padding-bottom: 0;
  }

  .work-community__title {
    font-size: 28px;
    letter-spacing: -0.28px;
    margin-bottom: 12px;
  }

  .swiper-work .swiper-slide {
    width: 686px;
  }

  .work-slider__img {
    height: 482px;
  }
}

@media (max-width: 768px) {
  .swiper-work .swiper-slide {
    width: 100%;
  }

  .work-community-body-column2__img2.one {
    display: none;
  }
}

@media (max-width: 576px) {

  .swiper-work,
  .work-frame-row {
    padding: 0;
  }

  .work-frame-row {
    gap: 12px;
    margin-bottom: 28px;
  }

  .frame-cover.work-frame {
    padding: 56px 20px 0 20px;
  }

  .work-community {
    margin-left: 0;
    margin-right: 0;
  }

  .work-community {
    margin-top: 40px;

    padding: 16px 20px 0 20px;
    border-radius: 24px 24px 0 0;
  }

  .work-community__title {
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .work-community-body {
    gap: 24px;
  }

  .work-slider__img {
    height: 236px;
  }

  .work-community-body-column2__img.two {
    display: none;
  }

  .work-community-body-column2__img2.one {
    display: flex;
    object-fit: cover;
    object-position: top;
    max-width: 300px;
  }

  .work-slide-imgBlock {
    border-radius: 24px;
  }

  .work-community__text {
    font-size: 16px;
    letter-spacing: -0.16px;
  }

  .work-frame .work-slide:hover .work-slide-imgBlock::before {
    border-radius: 24px;
  }
}
