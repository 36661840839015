.radioBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}

.radioBlock-Item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-radio + label {
  font-family: "PT Golos", sans-serif;
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: var(--design-bim-secondary-black);
  cursor: pointer;
}

.custom-radio + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;

  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid rgba(66, 66, 69, 0.5);
  border-radius: 50%;
  margin-right: 6px;
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-radio:checked + label::before {
  border-color: var(--main-color);
  background-image: url(img/checked-button.svg);
}

@media (max-width: 576px) {
  .radioBlock {
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
  }

  .radioBlock-Item {
    padding: 8px 0;
  }
}
