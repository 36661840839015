.location-modal {
  display: flex;
  gap: 16px;
  margin-top: 48px;
}

.yandex-reviews {
  height: 600px;
  flex: 1;
}

.yandex-map {
  margin-top: 20px;
  height: 550px;
  flex: 2;
  border-radius: 15px;
}

.modalMain-wrapper--location {
  max-width: 1221px;
  width: 100%;
}

@media (max-width: 768px) {
  .location-modal {
    flex-direction: column;
    min-height: 1000px;
  }

  .yandex-reviews {
    flex: 2;
  }

  .yandex-map {
    flex: 1;
  }
}
