.not-found {
  width: 100vw;
  height: calc(var(--vh) * 100 - 78px);
  background: var(--main-color);
  margin-top: 78px;
}

.not-found-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0;
}

.background-image {
  position: fixed;
}

.nf-info-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.nf-status {
  color: var(--online-color-text);
  leading-trim: both;
  text-edge: cap;
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 160px;
  font-style: normal;
  font-weight: 200;
  line-height: 115%;
  padding-top: 2px;
}

.nf-text {
  color: var(--online-color-text);
  font-family: 'ALS Gorizont', sans-serif;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 2px;
}

.nf-text-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.nf-button-area {
  display: flex;
  justify-content: center;
}

.nf-button {
  display: flex;
  padding: 18px 24px 18px 28px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'PT Golos', sans-serif;

  border-radius: 12px;
  border: 1px solid var(--design-bim-primary-black, #0D0D0D);
  background: var(--highlight-button);
}

@media (max-width: 576px) {
  .nf-status {
    font-size: 80px;
  }

  .nf-text {
    font-size: 20px;
  }

  .nf-button-area a,
  .nf-button-area a button{
    width: 100%;
    font-size: 17px;
  }

  .nf-info-area {
    padding-left: 20px;
    padding-right: 20px;
  }
}
