.courses-catalog {
  background-color: var(--background-color);
  width: calc(100vw - 10px);
}

@media (hover: none) and (pointer: coarse),
(hover: none) and (pointer: fine),
(hover: hover) and (pointer: coarse) {
  .courses-catalog {
    width: 100vw;
  }
}
