.form-request {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  min-height: calc(min(var(--vh) * 100, 800px) - 100px);
}

.request-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-request-course-title-area {
  display: flex;
  align-items: center;
  gap: 16px;
}

.form-request-course-title {
  margin-top: -25px;
  hyphens: auto;
  background: var(--main-color);
  color: var(--main-text-color);
  padding: 5px;
  border-radius: 7px;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'PT Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.form-request-tab {
  display: flex;
  align-items: center;
  gap: 16px;
}

.form-request-tab__item {
  padding: 12px 8px 12px 8px;
  border-bottom: 2px solid transparent;
  color: var(--design-bim-tertiary-black, #656d7c);
  font-size: 16px;
  font-weight: 400;
  font-family: 'ALS Gorizont', sans-serif;
  line-height: 140%;
  white-space: nowrap;
}

.form-request-tab__item:hover {
  color: var(--main-color);
  cursor: pointer;
}

.form-request-tab__item.selected {
  border-color: var(--main-color);
  color: var(--main-color);
}

.request-form-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.request-form-block__title {
  color: var(--design-bim-primary-black);
  font-family: "ALS Gorizont", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-top: 2px;
  line-height: 130%;
}

.request-form__text {
  color: rgba(66, 66, 69, 0.50);
  font-family: "PT Golos", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
  letter-spacing: 0.14px;
}

.request-form__text a {
  color: var(--main-color);
}

.request-form__text a:visited {
  color: inherit;
}

.request-form-buttons {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: auto;
}

@media (max-width: 768px) {
  .form-request {
    gap: 24px;
  }

  .request-form-buttons {
    flex-direction: column-reverse;
    gap: 16px;
  }

  .form-request-tab__item {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .request-form-buttons .button {
    width: 100%;
  }

  .form-request-tab {
    gap: 0px;
  }

  .radioBlock {
    flex-direction: column;
    gap: 12px;
  }

  .form-request-course-title {
    margin-top: -5px;
  }

  .form-request {
    min-height: calc(var(--vh) * 100 - 100px);
  }
}
