.salary-frame-cover-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
}

.salary-frame-cover-content-leftSide {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 24px;
  max-width: 946px;
}

.salary-frame-cover-content-rightSide {
  min-width: 382px;
  max-width: 382px;
}

.salary-frame-description {
  width: 90%;
  color: var(--design-bim-secondary-black);
  font-family: 'PT Golos', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.2px;
}

.salary-frame-description span {
  font-weight: 600;
}


@media (max-width: 1200px) {
  .salary-frame-cover-content-leftSide {
    max-width: inherit;
  }

  .salary-frame-cover-content-rightSide {
    display: none;
  }

  .salary-frame-cover-content {
    justify-content: center;
  }

  .salary-frame-description {
    width: 100%;
  }
}

@media (max-width: 576px) {

  .salary-frame-cover-content-rightSide {
    display: none;
  }

  .salary-frame-cover-content {
    justify-content: center;
  }

  .salary-frame-description {
    width: 100%;
    font-size: 16px;
    letter-spacing: initial;
  }
}
