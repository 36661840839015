.info-frame {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.info-frame-title-area {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.start-frame.catalog {
  padding-top: 78px;
  padding-bottom: 0;
}

@media (max-width: 1200px) {
  .start-frame.catalog {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .start-frame.catalog {
    padding-top: 72px;
  }

  .info-frame {
    gap: 32px;
  }
}
